<template>
    <div class="mentor-card">
        <div class="left-column">
            <div class="profile-image">
                <img :src="mentor.image" alt="Profile photo" />
            </div>
            <div class="likes">
                <button class="like-button" @click="toggleLike" :class="{ 'liked': isLiked }">
                    <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M2.31802 2.38052C0.56066 4.13788 0.56066 6.98712 2.31802 8.74448L10.0001 16.4265L17.682 8.74448C19.4393 6.98712 19.4393 4.13788 17.682 2.38052C15.9246 0.62316 13.0754 0.62316 11.318 2.38052L10.0001 3.69859L8.68198 2.38052C6.92462 0.62316 4.07538 0.62316 2.31802 2.38052Z"
                            stroke="#7B65F7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                </button>
                <span class="like-count">{{ mentor.likes }}</span>
            </div>
            <!-- <LinkToMentor :mentorId="mentor.id"> -->
            <LinkToMentor :mentorId="45">
                <button class="profile-button">В профиль</button>
            </LinkToMentor>
        </div>

        <div class="content">
            <div class="content-hero">
                <div class="title-group">
                    <h1 class="name">{{ mentor.name }}</h1>
                    <p class="position">{{ mentor.position }}</p>
                </div>
                <span class="price">{{ mentor.price }}</span>
            </div>

            <div class="divider"></div>

            <p class="description">
                {{ mentor.description }}
            </p>

            <div class="tags">
                <span v-for="tag in mentor.tags" :key="tag" class="tag">{{ tag }}</span>
            </div>
        </div>
    </div>
</template>

<script setup>
/* eslint-disable */
import { ref } from 'vue';
import LinkToMentor from '@/components/Links/LinkToMentor.vue';

const props = defineProps({
  mentor: {
    type: Object,
    required: true
  }
});

const isLiked = ref(false);

const toggleLike = () => {
    isLiked.value = !isLiked.value;
};
</script>

<style scoped>
.mentor-card {
    position: relative;
    width: 820px;
    height: 280px;
    background: #FFFFFF;
    border-radius: 10px;
    padding: 20px;
    display: flex;
    align-items: flex-start;
}

.left-column {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 30px;
}

.profile-image {
    width: 150px;
    height: 148.06px;
    border-radius: 136px;
    overflow: hidden;
    margin-bottom: 15px;
}

.profile-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.content {
    flex: 1;
}

.content-hero {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.name {
    font-family: 'Tilda Sans VF', sans-serif;
    font-weight: 600;
    font-size: 32px;
    line-height: 38px;
    color: #2A2037;
    margin: 0;
}

.position {
    font-family: 'Tilda Sans VF', sans-serif;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: #5747AF;
    margin: 10px 0 0;
}

.price {
    font-family: 'Tilda Sans VF', sans-serif;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #2A2037;
    letter-spacing: 0.01em;
    text-align: right;
}

.divider {
    width: 100%;
    height: 1px;
    background: #D5D7DC;
    margin: 20px 0;
}

.description {
    font-family: 'Tilda Sans VF', sans-serif;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: #525A7D;
    margin: 20px 0;
}

.likes {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 15px;
}

.like-button {
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #7B65F7;
    border-radius: 8px;
    background: none;
    cursor: pointer;
    padding: 0;
}

.like-button:hover {
    background: #F0EDFE;
}

.like-button.liked svg path {
    fill: #7B65F7;
}

.like-count {
    font-family: 'Tilda Sans VF', sans-serif;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #7B65F7;
}

.tags {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 20px;
}

.tag {
    display: inline-flex;
    align-items: center;
    padding: 1px 8px 4px;
    background: #FFFFFF;
    border: 1px solid #A2A5B3;
    border-radius: 16px;
    font-family: 'Tilda Sans VF', sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #434966;
    letter-spacing: 0.01em;
}

.profile-button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6px 12px;
    width: 150px;
    height: 36px;
    background: #C8C2FB;
    border-radius: 8px;
    border: none;
    font-family: 'Tilda Sans VF', sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #473A8F;
    letter-spacing: 0.01em;
    cursor: pointer;
}

.profile-button:hover {
    background: #B8B0FA;
}
</style>

