import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, TransitionGroup as _TransitionGroup, withCtx as _withCtx, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "page-container" }
const _hoisted_2 = { class: "main-content" }
const _hoisted_3 = { class: "page-header" }
const _hoisted_4 = { class: "search-bar hidden md:block" }
const _hoisted_5 = { class: "user-notifications" }
const _hoisted_6 = { class: "tabs" }
const _hoisted_7 = ["onClick"]
const _hoisted_8 = {
  key: 0,
  class: "mobile-calendar"
}
const _hoisted_9 = { key: 0 }
const _hoisted_10 = { class: "event-header" }
const _hoisted_11 = {
  key: 0,
  class: "live-badge"
}
const _hoisted_12 = {
  key: 0,
  class: "event-time"
}
const _hoisted_13 = { class: "event-actions" }
const _hoisted_14 = {
  key: 0,
  class: "btn-primary"
}
const _hoisted_15 = {
  key: 1,
  class: "btn-secondary"
}
const _hoisted_16 = { class: "btn-text" }
const _hoisted_17 = { key: 1 }
const _hoisted_18 = { class: "event-header" }
const _hoisted_19 = { class: "event-time" }
const _hoisted_20 = { key: 2 }
const _hoisted_21 = { class: "event-time" }
const _hoisted_22 = {
  key: 1,
  class: "mobile-nav"
}
const _hoisted_23 = ["onClick"]
const _hoisted_24 = {
  key: 0,
  class: "sidebar-right"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SearchIcon = _resolveComponent("SearchIcon")!
  const _component_BellIcon = _resolveComponent("BellIcon")!
  const _component_CalendarSection = _resolveComponent("CalendarSection")!
  const _component_CircleIcon = _resolveComponent("CircleIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("main", _hoisted_2, [
      _createElementVNode("header", _hoisted_3, [
        _cache[2] || (_cache[2] = _createElementVNode("h1", null, "Календарь", -1)),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_SearchIcon, { class: "search-icon" }),
          _cache[0] || (_cache[0] = _createElementVNode("input", {
            type: "text",
            placeholder: "Искать менторов"
          }, null, -1))
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_BellIcon, { class: "icon" }),
          _cache[1] || (_cache[1] = _createElementVNode("span", { class: "notification-badge" }, "2", -1))
        ])
      ]),
      _createElementVNode("div", _hoisted_6, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs, (tab) => {
          return (_openBlock(), _createElementBlock("button", {
            key: tab.id,
            class: _normalizeClass(['tab-button', { active: _ctx.currentTab === tab.id }]),
            onClick: ($event: any) => {_ctx.currentTab = tab.id; console.log(_ctx.pendingEvents)}
          }, _toDisplayString(tab.name), 11, _hoisted_7))
        }), 128))
      ]),
      (_ctx.isMobile && _ctx.currentTab === 'schedule')
        ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
            _createVNode(_component_CalendarSection, { class: "calendar-sectionn" })
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", null, [
        _createVNode(_TransitionGroup, {
          name: "list",
          tag: "div",
          class: "events-list"
        }, {
          default: _withCtx(() => [
            (_ctx.currentTab === 'upcoming')
              ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.upcomingEvents, (event) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: event.id,
                      class: "event-card"
                    }, [
                      _createElementVNode("div", _hoisted_10, [
                        _createElementVNode("h3", null, _toDisplayString(event.title), 1),
                        (event.status === 'live')
                          ? (_openBlock(), _createElementBlock("span", _hoisted_11, [
                              _createVNode(_component_CircleIcon, { class: "icon" }),
                              _createTextVNode(" В эфире через " + _toDisplayString(event.startIn), 1)
                            ]))
                          : _createCommentVNode("", true)
                      ]),
                      (event.datetime)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_12, _toDisplayString(_ctx.formatDate(event.datetime)), 1))
                        : _createCommentVNode("", true),
                      _createElementVNode("div", _hoisted_13, [
                        (event.status === 'live')
                          ? (_openBlock(), _createElementBlock("button", _hoisted_14, " Присоединиться "))
                          : (_openBlock(), _createElementBlock("button", _hoisted_15, " Отменить запись ")),
                        _createElementVNode("button", _hoisted_16, _toDisplayString(event.status === 'live' ? 'Отменить запись' : 'Подробнее'), 1)
                      ])
                    ]))
                  }), 128))
                ]))
              : _createCommentVNode("", true),
            (_ctx.currentTab === 'pending')
              ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pendingEvents, (event) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: event.id,
                      class: "event-card pending"
                    }, [
                      _createElementVNode("div", _hoisted_18, [
                        _createElementVNode("h3", null, _toDisplayString(event.title), 1),
                        _cache[3] || (_cache[3] = _createElementVNode("span", { class: "pending-badge" }, "На рассмотрении", -1))
                      ]),
                      _createElementVNode("div", _hoisted_19, _toDisplayString(_ctx.formatDate(event.datetime)), 1),
                      _cache[4] || (_cache[4] = _createElementVNode("div", { class: "event-actions" }, [
                        _createElementVNode("button", { class: "btn-secondary" }, "Отменить запись"),
                        _createElementVNode("button", { class: "btn-text" }, "Подробнее")
                      ], -1))
                    ]))
                  }), 128))
                ]))
              : _createCommentVNode("", true),
            (_ctx.currentTab === 'history')
              ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.historyEvents, (event) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: event.id,
                      class: "event-card history"
                    }, [
                      _cache[5] || (_cache[5] = _createElementVNode("div", { class: "event-header" }, [
                        _createElementVNode("h3", null, "Стандартное название мероприятия")
                      ], -1)),
                      _createElementVNode("div", _hoisted_21, _toDisplayString(_ctx.formatDate(event.datetime)), 1),
                      _cache[6] || (_cache[6] = _createElementVNode("div", { class: "event-actions" }, [
                        _createElementVNode("button", { class: "btn-secondary" }, "Искать похожие"),
                        _createElementVNode("button", { class: "btn-text" }, "Детали")
                      ], -1))
                    ]))
                  }), 128))
                ]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ]),
      (_ctx.isMobile)
        ? (_openBlock(), _createElementBlock("nav", _hoisted_22, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.navigationItems, (item) => {
              return (_openBlock(), _createElementBlock("button", {
                key: item.id,
                class: _normalizeClass(['mobile-nav-item', { active: _ctx.currentTab === item.id }]),
                onClick: ($event: any) => (_ctx.currentTab = item.id)
              }, [
                (_openBlock(), _createBlock(_resolveDynamicComponent(item.icon), { class: "icon" })),
                _createElementVNode("span", null, _toDisplayString(item.name), 1)
              ], 10, _hoisted_23))
            }), 128))
          ]))
        : _createCommentVNode("", true)
    ]),
    (!_ctx.isMobile)
      ? (_openBlock(), _createElementBlock("aside", _hoisted_24, [
          _createVNode(_component_CalendarSection, { class: "calendar-sectionn" })
        ]))
      : _createCommentVNode("", true)
  ]))
}