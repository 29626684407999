<template>
    <div class="blacklist">
        <p class="description">
            Как только вы заблокируете кого-либо, этот человек больше не сможет просматривать ваш профиль,
            бронировать сеанс, отправлять вам сообщения или приглашать вас на групповые сеансы.
        </p>

        <div class="blocked-users">
            <div v-for="user in blockedUsers" :key="user.id" class="blocked-user">
                <div class="user-info">
                    <img src="https://s3-alpha-sig.figma.com/img/20de/aa15/2bc47700738d638647dd42ffe8d6b6ed?Expires=1734912000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=gGsMZ6la5ln9~6Ib24vxBxDz4PFwk58c4AlgFUb0SBx8BPySeT2~k~t0gY030DWi15swipCLX5pFyC2IwYqPSNLC~cj~VWLbn9Kc1atbxYPQkDvuOIZz52KHcCgWAQsk2nt43YvQqZ6~FNt1BrKq5LtMxG7mtZgvn24SLjdToK1u7MPMfs2hocDySRRacJnF6icAPbZcmOYuK84chZbbOKMOUlPWRTzFQks1GBat9XFRXJvDCwXzXW1rHpe4uEJOCdKsHGZ2am9xvzQAvASrVPRjkRJbqumd2mYZmCOtTbs0UjurfgTUCb13Vnc5fyl2aldJkIiBIq53mYEpFgBqUg__" :alt="user.name" class="user-avatar">
                    <div class="user-details">
                        <div class="user-name">{{ user.name }}</div>
                        <div class="user-role">{{ user.role }}</div>
                    </div>
                </div>
                <button class="unblock-button" @click="unblockUser(user.id)">
                    <XIcon class="icon" />
                </button>
            </div>
        </div>
    </div>
</template>

<script setup>
/* eslint-disable */
import { ref } from 'vue'
import { XIcon } from 'lucide-vue-next'

const blockedUsers = ref([
    {
        id: 1,
        name: 'Сергей Мейерхольд',
        role: 'Тренер по бариста',
        avatar: '/placeholder.svg'
    },
    {
        id: 2,
        name: 'Сергей Мейерхольд',
        role: 'Тренер по бариста',
        avatar: '/placeholder.svg'
    },
    {
        id: 3,
        name: 'Сергей Мейерхольд',
        role: 'Тренер по бариста',
        avatar: '/placeholder.svg'
    }
])

const unblockUser = (userId) => {
    blockedUsers.value = blockedUsers.value.filter(user => user.id !== userId)
}
</script>

<style scoped>
.blacklist {
    max-width: 640px;
}

.description {
    color: #475467;
    font-size: 14px;
    line-height: 1.5;
    margin-bottom: 32px;
}

.blocked-users {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.blocked-user {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    background: #FFFFFF;
    border: 1px solid #EAECF0;
    border-radius: 8px;
}

.user-info {
    display: flex;
    align-items: center;
    gap: 12px;
}

.user-avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
}

.user-name {
    font-weight: 500;
    color: #101828;
    margin-bottom: 4px;
}

.user-role {
    font-size: 14px;
    color: #475467;
}

.unblock-button {
    background: none;
    border: none;
    padding: 8px;
    cursor: pointer;
    color: #98A2B3;
}

.icon {
    width: 20px;
    height: 20px;
}
</style>