<template>
    <div class="calendar-section">
        <v-date-picker v-model="date" view="weekly" mode="date" @update:model-value="onDateSelect"/>
        <div class="events-list">
            <h3 class="events-title">События на {{ formattedSelectedDate }}</h3>
            <div v-if="selectedEvents.length > 0" class="event-items">
                <div v-for="event in selectedEvents" :key="event.id" class="event-item">
                    <div class="event-date">{{ formatDay(event.date) }}</div>
                    <div class="event-info">
                        <h4 class="event-title">{{ event.title }}</h4>
                        <p class="event-details">{{ event.time }} · {{ event.duration }}</p>
                    </div>
                </div>
            </div>
            <p v-else class="no-events">Нет событий на выбранную дату</p>
        </div>
    </div>
</template>

<script>
/* eslint-disable */
import { defineComponent, ref, computed } from 'vue';
// import VCalendar  from 'v-calendar';

export default defineComponent({
    name: 'CalendarSection',
    // components: {
    //     'v-calendar': VCalendar,
    // },
    setup() {
        const selectedDate = ref(new Date());
        console.log()
        const events = ref([
            { id: 1, date: new Date(2024, 12, 13), title: 'Сияй изнутри: питание..', time: '12:00', duration: '1 час' },
            { id: 2, date: new Date(2024, 12, 13), title: 'Сияй изнутри: питание..', time: '14:00', duration: '1 час' },
            { id: 3, date: new Date(2024, 12, 13), title: 'Медитация', time: '10:00', duration: '30 минут' },
            { id: 4, date: new Date(2024, 12, 12), title: 'Сияй изнутри: питание..', time: '12:00', duration: '1 час' },
            { id: 5, date: new Date(2024, 12, 12), title: 'Сияй изнутри: питание..', time: '14:00', duration: '1 час' },
            { id: 14, date: new Date(2024, 12, 14), title: 'Медитация', time: '10:00', duration: '30 минут' },
            { id: 6, date: new Date(2024, 13, 12), title: 'Медитация с Ксюшей Овалиной', time: '10:00', duration: '30 минут' },
            { id: 7, date: new Date(2024, 13, 12), title: 'Сияй изнутри: питание..', time: '12:00', duration: '1 час' },
            { id: 8, date: new Date(2024, 13, 12), title: 'Сияй изнутри: питание..', time: '14:00', duration: '1 час' },
            { id: 9, date: new Date(2024, 13, 12), title: 'Медитация', time: '10:00', duration: '30 минут' },
            { id: 10, date: new Date(2024, 12, 12), title: 'Сияй изнутри: питание..', time: '12:00', duration: '1 час' },
            { id: 11, date: new Date(2024, 12, 12), title: 'Сияй изнутри: питание..', time: '14:00', duration: '1 час' },
            { id: 12, date: new Date(2024, 14, 12), title: 'Медитация', time: '10:00', duration: '30 минут' },
            { id: 13, date: new Date(2024, 14, 12), title: 'Медитация с Ксюшей Овалиной', time: '10:00', duration: '30 минут' },
        ]);

        const calendarAttributes = computed(() => {
            return events.value.map(event => ({
                dot: true,
                dates: event.date,
            }));
        });

        const selectedEvents = computed(() => {
            events.value.filter(event => 
                console.log('event.date.toDateString() is ', event.date.toDateString(),  'selectedDate.value.toDateString() is ', selectedDate.value.toDateString())
            );
            return events.value.filter(event => 
                event.date.toDateString() === selectedDate.value.toDateString()
            );

        });

        const onDayClick = (day) => {
            selectedDate.value = day.date;
        };

        const formattedSelectedDate = computed(() => {
            return new Intl.DateTimeFormat('ru-RU', { 
                year: 'numeric', 
                month: 'long', 
                day: 'numeric' 
            }).format(selectedDate.value);
        });

        const formatDay = (date) => {
            return new Intl.DateTimeFormat('ru-RU', { day: 'numeric' }).format(date);
        };

        const onDateSelect = (newDate) => {
            selectedDate.value = newDate;
        };

        return {
            selectedDate,
            calendarAttributes,
            selectedEvents,
            onDateSelect,
            formattedSelectedDate,
            formatDay
        };
    },
});
</script>

<style scoped>
.calendar-section {
    background-color: #fff;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.events-list {
    margin-top: 20px;
}

.events-title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 15px;
}

.event-items {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.event-item {
    display: flex;
    align-items: center;
    background-color: #f9f9f9;
    padding: 15px;
    border-radius: 12px;
}

.event-date {
    width: 40px;
    height: 40px;
    background-color: #e0e0e0;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: bold;
    margin-right: 15px;
}

.event-info {
    flex: 1;
}

.event-title {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 5px;
}

.event-details {
    font-size: 14px;
    color: #666;
}

.no-events {
    text-align: center;
    color: #666;
    font-style: italic;
}
</style>