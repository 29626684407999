<template>
    <div class="chats-page">
      <h1>Чаты</h1>
      <p>Скоро здесь появятся чаты</p>
    </div>
  </template>
  
  <script lang="ts">
  export default {
    name: 'ChatsPage'
  }
  </script>
  
  <style scoped>
  .chats-page {
    padding: 20px;
    text-align: center;
  }
  
  h1 {
    font-size: 24px;
    margin-bottom: 16px;
  }
  
  p {
    font-size: 16px;
    color: #666;
  }
  </style>