<template>
    <div>
        <!-- Regular fields display -->
        <div v-if="!editingField" class="fields">
            <div v-for="field in fields" :key="field.id" class="field">
                <div class="field-label">{{ field.label }}</div>
                <div class="field-content">
                    <div class="field-value">{{ field.value }}</div>
                    <button class="edit-button" @click="startEditing(field.id)">
                        Редактировать
                    </button>
                </div>
            </div>
        </div>

        <!-- Edit name form -->
        <div v-if="editingField === 'name'" class="edit-form">
            <div class="field-label">Полное имя</div>
            <input type="text" v-model="editValue" class="form-input" placeholder="Введите имя">
            <div class="form-actions">
                <button class="cancel-button" @click="cancelEditing">Отмена</button>
                <button class="update-button" @click="saveEdit">Обновить</button>
            </div>
        </div>

        <!-- Edit expertise form -->
        <div v-if="editingField === 'expertise'" class="edit-form">
            <div class="field-label">Экспертиза</div>
            <div class="expertise-select">
                <div class="expertise-tags">
                    <button v-for="tag in ['Бариста', 'Общепит']" :key="tag" class="expertise-tag"
                        :class="{ active: selectedExpertise.includes(tag) }" @click="toggleExpertise(tag)">
                        {{ tag }}
                    </button>
                </div>
            </div>
            <div class="form-actions">
                <button class="cancel-button" @click="cancelEditing">Отмена</button>
                <button class="update-button" @click="saveEdit">Обновить</button>
            </div>
        </div>

        <!-- Profile cover section -->
        <div class="cover-section">
            <div class="field-label">Обложка профиля</div>
            <div class="field-content">
                <div class="field-value">
                    Добавьте фото или видео обложку. Фото 1920x1080 до 5 мб. Видео 1920x1080 до 15 мб.
                </div>
                <button class="edit-button">Редактировать</button>
            </div>
            <div class="cover-upload">
                <button class="upload-button">
                    <UploadIcon class="icon" />
                    Загрузите обложку
                </button>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue'
import { UploadIcon } from 'lucide-vue-next'

const editingField = ref(null)
const editValue = ref('')
const selectedExpertise = ref(['Бариста', 'Общепит'])

const fields = ref([
    { id: 'name', label: 'Имя', value: 'Сергей Мейерхольд' },
    { id: 'gender', label: 'Пол', value: 'Мужской' },
    { id: 'country', label: 'Страна пребывания', value: 'Россия' },
    { id: 'expertise', label: 'Экспертиза', value: 'Бариста, Общепит' },
    { id: 'profession', label: 'Профессия', value: 'Бариста' },
    { id: 'languages', label: 'Языки', value: 'Русский, English' }
])

const startEditing = (fieldId) => {
    editingField.value = fieldId
    const field = fields.value.find(f => f.id === fieldId)
    editValue.value = field.value
}

const cancelEditing = () => {
    editingField.value = null
    editValue.value = ''
}

const saveEdit = () => {
    const field = fields.value.find(f => f.id === editingField.value)
    if (editingField.value === 'expertise') {
        field.value = selectedExpertise.value.join(', ')
    } else {
        field.value = editValue.value
    }
    editingField.value = null
}

const toggleExpertise = (tag) => {
    const index = selectedExpertise.value.indexOf(tag)
    if (index === -1) {
        selectedExpertise.value.push(tag)
    } else {
        selectedExpertise.value.splice(index, 1)
    }
}
</script>

<style scoped>
.profile {
    max-width: 640px;
}

.field {
    padding: 24px 0;
    border-bottom: 1px solid #EAECF0;
}

.field-label {
    font-size: 14px;
    font-weight: 500;
    color: #344054;
    margin-bottom: 4px;
}

.field-content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.field-value {
    color: #101828;
    font-size: 14px;
}

.edit-button {
    background: none;
    border: none;
    color: #7F56D9;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    padding: 0;
}

.edit-form {
    padding: 24px 0;
    border-bottom: 1px solid #EAECF0;
}

.form-input {
    width: 100%;
    padding: 10px 14px;
    border: 1px solid #D0D5DD;
    border-radius: 8px;
    font-size: 14px;
    margin: 12px 0;
}

.form-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 16px;
}

.cancel-button {
    background: none;
    border: none;
    color: #475467;
    font-size: 14px;
    cursor: pointer;
}

.update-button {
    background: #7F56D9;
    color: white;
    border: none;
    padding: 10px 18px;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
}

.expertise-select {
    margin: 12px 0;
}

.expertise-tags {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
}

.expertise-tag {
    padding: 8px 14px;
    border: 1px solid #D0D5DD;
    border-radius: 16px;
    font-size: 14px;
    color: #344054;
    background: white;
    cursor: pointer;
}

.expertise-tag.active {
    background: #F4EBFF;
    border-color: #7F56D9;
    color: #7F56D9;
}

.cover-section {
    margin-top: 24px;
}

.cover-upload {
    background: white;
    border-radius: 8px;
    aspect-ratio: 16/9;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 12px;
}

.upload-button {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 10px 16px;
    background: #f0f0f0;
    border: 1px solid #D0D5DD;
    border-radius: 8px;
    color: #475467;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
}

.icon {
    width: 20px;
    height: 20px;
}
</style>