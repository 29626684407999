<template>
    <div class="border">
        <div class="col col-left">
            <div class="txtleftcolumn">
                <div class="title">
                    Станьте наставником для увлеченных людей со всего мира
                </div>
                <div class="text">
                    Делитесь своими знаниями и зарабатывайте на этом. 
                    Проводите индивидуальные занятия или открытые мастер-классы, 
                    а мы найдем вам первых учеников и возьмем всю организационную работу на себя
                </div>
                <Spacer16/>
                <Spacer16/>
                <div class="buttons">
                    <div @click="navigateToPage('/register')" class="button1">
                        Заполнить анкету
                    </div>
                    <div class="button2">
                        Подробнее
                    </div>
                </div>
            </div>
        </div>
        <div class="col col-right">
            <img src='../../assets/images/landing/tormen.svg' alt='Top Left Image' class="tormen-img">
        </div>
        <div class="col col-right">
            <div class="magic">
                <div class="photos">
                    <img v-for="(src, index) in this.imageURLs" :key="index" :src="src" :alt="'Image ' + (index + 1)" class="overlay-image" :style="getStyle(index)">
                </div>
                <div>
                    <div class="text1">
                        2000+ наставников уже с нами
                    </div>
                    <Spacer8/>
                    <div class="text2">
                        Специалисты в разных сферах и с разным опытом
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Spacer16 from '../Spacers/SpacerModule16.vue';
    import Spacer8 from '../Spacers/SpacerModule8.vue';
    
    export default {
        name: 'HomeQuestionerMain',
        components: {
            Spacer16,
            Spacer8,
        },
        data() {
            return {
                imageURLs: [
                    'https://s3-alpha-sig.figma.com/img/20de/aa15/2bc47700738d638647dd42ffe8d6b6ed?Expires=1734912000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=gGsMZ6la5ln9~6Ib24vxBxDz4PFwk58c4AlgFUb0SBx8BPySeT2~k~t0gY030DWi15swipCLX5pFyC2IwYqPSNLC~cj~VWLbn9Kc1atbxYPQkDvuOIZz52KHcCgWAQsk2nt43YvQqZ6~FNt1BrKq5LtMxG7mtZgvn24SLjdToK1u7MPMfs2hocDySRRacJnF6icAPbZcmOYuK84chZbbOKMOUlPWRTzFQks1GBat9XFRXJvDCwXzXW1rHpe4uEJOCdKsHGZ2am9xvzQAvASrVPRjkRJbqumd2mYZmCOtTbs0UjurfgTUCb13Vnc5fyl2aldJkIiBIq53mYEpFgBqUg__',
                    'https://s3-alpha-sig.figma.com/img/c1a5/8860/d2ed448f6dd014c1899368f0f8f5629f?Expires=1734912000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=iekiaWiunVPjNrZlhm26zQ4pApMNk6ryrN0~-oD7VOq5ec5YFG9w7oWxYMQ-s0Fu53jMFDlDVNqJPQud9nwXsEx4Dg8J7HYVoFXbPNiQQ9W26dp1qx-x9TImCZ2nIYPwXmNnu21G5zhMBiK5j~vfxNiU67fHCmVEBaEQIhwFS8id1Gv8T67Afro0WTZLsrrsJPwmZrRkwbveqUCghnlm9Pc4Q1qMBBs11XU~CTMPh59gkqWFnEfNK~P-R1KBHbL1M39U6doH1omiYUpa8TZG98DJ54cqz4eAw48C6MWetB8XiViSJG1OcP~snJ169YIKOqIGOswMCr1qikFywuJQAA__',
                    'https://s3-alpha-sig.figma.com/img/418a/f7ad/deab4a457eff43838555f24a193d678f?Expires=1734912000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=YpwJgC6gDkmKtaFOc3mqOruDvGx91AuRR3luMvAO7wOT3wFa4vQ28RAQFXcTZYMBDXDi1bSn3YQTwOeLQnWSaowd2FDbXgLc5KCPImuuCttq60-FzaMUurvzK8yt2syg3LRr~pehPYDgoW~Gm3EzdPG5-D3z7laYDJAenwroc2h4kZaS-Y-y85IDa6tc99rIiZGSkjewPHZuDtl1duJOjyRGBCG5RqKmlaCp3AJtYQLJXiNxKVfuJWn9J0CzinFSLw4z-MuZnaNY36CCL2AIeXYIxQbwppo~uZKMSdabDvtQohRp~zdsb99rde~IReQwUnBN~8vXGbGXKhhE4bL8GA__',
                    'https://s3-alpha-sig.figma.com/img/20de/aa15/2bc47700738d638647dd42ffe8d6b6ed?Expires=1734912000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=gGsMZ6la5ln9~6Ib24vxBxDz4PFwk58c4AlgFUb0SBx8BPySeT2~k~t0gY030DWi15swipCLX5pFyC2IwYqPSNLC~cj~VWLbn9Kc1atbxYPQkDvuOIZz52KHcCgWAQsk2nt43YvQqZ6~FNt1BrKq5LtMxG7mtZgvn24SLjdToK1u7MPMfs2hocDySRRacJnF6icAPbZcmOYuK84chZbbOKMOUlPWRTzFQks1GBat9XFRXJvDCwXzXW1rHpe4uEJOCdKsHGZ2am9xvzQAvASrVPRjkRJbqumd2mYZmCOtTbs0UjurfgTUCb13Vnc5fyl2aldJkIiBIq53mYEpFgBqUg__',

                ],
                offset: 14,
            };
        },
        methods: {
            getStyle(index) {
                return {
                    marginLeft: index === 0 ? '0px' : `-${this.offset}px`,
                    zIndex: this.imageURLs.length + index,
                };
            },
            navigateToPage(route) {
                this.$router.push(route);
            }
        }
    }

</script>

<style scoped>
    html, body {
        margin: 0;
    }
    .border {
        display: grid;
        grid-template-columns: 2fr 1fr; /* Две колонки по ширине */
        grid-template-rows: 1fr 1fr; /* Две строки одинаковой высоты */
        height: 50em; /* Высота 95% */
        gap: 20px;
        color: #525A7D
    }

    .col {
        border-radius: 32px;
        background-color: #F3F4F5;
        display: grid; /* Используем Grid для центрирования текста */
    }

    .col-left {
        grid-column: 1; 
        grid-row: span 2;
    }  

    .col-right:nth-child(2) {
        grid-column: 2;
        grid-row: 1;
    }

    .col-right:nth-child(3) {
        grid-column: 2; 
        grid-row: 2; 
    }

    .txtleftcolumn {
        padding: 156px 56px;
    }
    .title {
        font-size: 80px;
        font-weight: 550;
        line-height: 84px;
        letter-spacing: -0.02em;
        text-align: left;
    }
    .text {
        padding-top: 48px;
        max-width: 556px;
        font-size: 20px;
        font-weight: 350;
        line-height: 24px;
        text-align: left;
    }

    .buttons {
        display: flex;
        flex-direction: row;
        gap: 12px;
    }
    .button1 {
        display: flex; 
        border-radius: 32px;
        padding: 14px 24px;
        background-color: #7B65F7;
        color: white;
    }
    .button2 {
        display: flex; 
        border-radius: 32px;
        padding: 14px 24px;
        background-color: #fff;
        border: 1px solid #DAD9DC;
        color: #2F3448
    }
    .button1:hover {
        cursor: pointer;
        background-color: #5343a9;
    }
    .button2:hover {
        cursor: pointer;
        background-color: #DAD9DC
    }
    .overlay-image {
        border-radius: 100px;
        width: 64px;
        height: 64px;
        object-fit: cover;
        border: 2px solid white;
    }

    .overlay-image:first-child {
        margin-left: 0; /* No offset for the first image */
    }

    .photos {
        display: grid;
        grid-auto-flow: column;
        align-items: center;
        width: 50%;
        
        
        position: relative;
    }

    .magic {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 24px 20px;
    }

    .text1 {
        font-size: 24px;
        font-weight: 500;
        line-height: 28px;
        letter-spacing: -0.01em;
        text-align: left;
    }
   
    .text2 {
        font-size: 20px;
        font-weight: 400;
        line-height: 24px;
        text-align: left;

    }

    .tormen-img {
        width: 100%;
        object-fit: cover;
    }
</style>
