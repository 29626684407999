<template>
    <div class="event-page">
        <!-- <header class="header">
        <div class="logo">ФОРМА</div>
        <nav class="nav">
          <select class="select desktop-only">
            <option>Выбрать</option>
          </select>
          <div class="search-container desktop-only">
            <input type="text" class="search-input" placeholder="Искать менторов" />
            <span class="search-icon">🔍</span>
          </div>
          <div class="avatar desktop-only"></div>
          <button class="menu-button mobile-only">☰</button>
        </nav>
      </header> -->
        <div class="content">
            <main class="main">
                <div class="main-content">
                    <div class="tags">
                        <span class="tag">18+</span>
                        <span class="tag">Еда</span>
                        <span class="tag">Здоровье</span>
                        <span class="tag">ЗОЖ</span>
                    </div>
                    <h1 class="title">Загораем на солнце правильно. Без ожогов и вреда для кожи</h1>
                    <p class="date">17 окт, 12:00 (GMT +05:00)</p>
                    <img src="https://yandex-images.clstorage.net/1d0qc0321/f0b547M0fTI/6ceZXxYflH5u3i_r8gy6OFEyU6C3aFLwgI7YH8oYe9FdPKthJhW5V1dWiDpL53XibTfVZM-Pd4yyQAmHTFbi2HxWDU7BjlR_K8ztePSPvj0CNQNl5-4T8VbN2EZEkbMPhrfnPq5L5kWWAVx6GfRlcuKsEKSeWmzGs1wDhVzIdcvlhBiaVgbcfy5NYG98wX46jnCX7uFuz_jLVcLNit27gszyInYjCm5AH0Ffcx1vr7fsLkTmHBkvTXOUDIbRgbbHawaUGlJA0Tbjxq8iccPw9IA11OFk6Uw5ytMOQ4VVvQXEcS41Ls2vAZ6D0TkSbORzrCONoR7Zo8lx2Q9Nm0P_jqyOGp3WzVl_4c5jb_yE-j0LOR-srasOf0SZQsKDlHoDhHFrNmEEoI7aDxdwFSsq8SfrDa1RGGGLP5dNTRLEME3iANRe0YxdeiROpKD6TnfyR_Jfp6qkTrzMmEEKQh9xSYy_4zEuT--CUA_Q95KjK3bjKkvgW5guDv-fQkUSCLFBbIwVH1pEUj7shilm-EFyvQfxl6ZgakkzCVqIj4IZuswMfqM6ZQguQBSLHvZdK2O-LuELLJreqks3Ws_HEUtyjO0PnB3YjdY9ow0l7fWFOb-PfdHnZOkIN8HcQggO37BFD_3i9GaCpk5bQpsyXGtqPuwvgObRH--AvhjKCNTHfUEpD9uX2MfbvuNOKOr8SHFzz_PZZ-zmT3xEnI4JApizhcS_IXUrDW6LngDft91vK__v4gRgk1DuT3tXi8oTT31IrMiU0JzOnHVkC-ytMU95vco1WyHpJgd3wJqMxA3ZOY9KNudxZYXlSFhAXj6Zqu12ZSOE5h9eJwu6kUxF08C4TiUDn1cRxR485oTqafyH-bwHMlEjKWwP9sQaQQCH3DkMw_8t_ydBb0BVQRl5E6TlPm-nxafR3inJ-ZGHAtNGdgiky1wWF0xTNO5ILWp9zHl4CnGQpuAjy7KDG49IS8"
                        alt="Event image" class="event-image" />
                    <div class="participation-info mobile-only">
                        <p class="event-price">Бесплатно</p>
                        <p class="available-seats">Свободно 30 мест</p>
                        <!-- <div 
                            v-if="currentEvent.value && currentEvent.value.event_members_id && !(currentEvent.value.event_members_id.indexOf(memberId.value) >= 0)" 
                            @click="registerMemberToEvent" 
                            class="participate-button"
                        >Участвовать</div> -->
                        <div v-if="canParticipate" @click="registerMemberToEvent" class="participate-button">Участвовать
                        </div>
                        <div v-else class="participate-button-registered">Зареган</div>
                        <!-- <div v-if="!(currentEvent.value.event_members_id.indexOf(memberId.value) >= 0 )" @click="registerMemberToEvent" class="participate-button">Участвовать</div>
                        <div v-else v-click="registerMemberToEvent" class="participate-button">Зареган</div> -->

                    </div>
                    <div class="share-link mobile-only">
                        <input type="text" value="https://forma.ru/session/zagorayemna..." readonly
                            class="share-input" />
                        <button class="copy-button">Копировать</button>
                    </div>
                    <p class="participants mobile-only">133/200 уже участвуют</p>
                    <div style="padding: 1rem; background-color: white; border-radius: 20px; margin-bottom: 1rem;">
                        <h2 class="section-title">Тема</h2>
                        <ol class="topic-list">
                            <li>Что такое критические путешествия пользователей и почему вы должны их использовать?</li>
                            <li>Схема поиска и расстановки приоритетов CUJ</li>
                            <li>Как превратить CUJ в дорожную карту продукта</li>
                            <li>Мерный СТАКАН счастья для постоянного совершенствования</li>
                        </ol>
                        <h2 class="section-title">Чему научитесь</h2>
                        <p class="description">
                            Google был одним из первых сторонников критических пользовательских путешествий (CUJ) и
                            того,
                            как они помогают продуктовым командам в большей степени ориентироваться на пользователей.
                            Когда
                            Google рассказывает о CUJ's, это кажется таким понятным и очевидным - все равно что
                            наблюдать за
                            плавными ударами теннисиста на Открытом чемпионате США и думать: "Ну, я мог бы так ударить
                            по
                            мячу - по крайней мере, один раз". Однако дьявол кроется в деталях.
                        </p>
                    </div>
                    <div class="cancellation-policy">
                        <h3 class="policy-title">Правила отмены</h3>
                        <p class="policy-text">
                            Полный возврат доступен при отмене не позднее чем за 7 дней до начала мастер-класса или в
                            течение 24 часов с момента бронирования
                        </p>
                    </div>
                    <!-- reviews -->



                    <div class="reviews ">
                        <!-- <div v-if="hasEvent" style="width: 100%"> -->
                        <ReviewBlock instance="event" :id="currentEvent.id" :reviews="reviews">
                        </ReviewBlock>
                        <!-- </div> -->
                        <!-- <div>{{ reviews }}</div> -->
                        <!-- <div>{{ reviews.value.length }}</div>  -->
                        <!-- <h2 class="section-title">Отзывы 18 <a href="#" class="add-review">Добавить</a></h2>
                        <div v-for="i in 3" :key="i" class="review">
                            <div class="review-avatar"></div>
                            <div class="review-content">
                                <h3 class="review-author">Ольга Захарова</h3>
                                <p class="review-text">
                                    Отличный опыт. Намного лучше, чем ожидалось. Сергей большой профессионал, он привлек
                                    мое внимание с самого начала. Совершенно рекомендую!
                                </p>
                            </div>
                        </div>
                        <div class="pagination">
                            <button class="pagination-button">←</button>
                            <button class="pagination-button">→</button>
                        </div> -->
                        <!-- lsls -->
                    </div>
                    <!-- fdvdfvdv -->
                    <div class="masonry-container">
                        <!-- Each column -->
                        <div class="masonry-column" v-for="n in 3" :key="n">
                            <div v-for="(event, index) in events.filter((_, i) => i % 3 === n - 1)" :key="index">
            
                                <link-to-event :id="event.id">
            
                                    <!-- <router-link :to="{name: 'EventInfo', params: {id: event.id} }" style="text-decoration: none; color: inherit;"> -->
                                    <EventCardVue :event_title="event.event_title" :event_date="event.event_date"
                                        :event_price="event.event_price" :event_imageUrl="event.event_imageUrl"
                                        :event_ageRestriction="event.event_ageRestriction"
                                        :event_availiableSpots="event.event_availiableSpots"
                                        :event_totalSpots="event.event_totalSpots" :event_category="event.event_category"
                                        :id="event.id">
                                    </EventCardVue>
                                </link-to-event>
                                <!-- </router-link> -->
                            </div>
                        </div>
                    </div>
                    <div class="related-events ">

                        <!-- <div class="event-grid">
                            <div v-for="i in 3" :key="i" class="event-card">
                                <div class="event-image-placeholder"></div>
                                <h3 class="event-card-title">Сияй изнутри: питание и привычки для здоровой кожи</h3>
                                <p class="event-card-author">Сергей Мейерхольд</p>
                                <p class="event-card-date">17 окт, 12:00 (GMT +05:00)</p>
                                <p class="event-card-price">500 руб</p>
                                <button class="bookmark-button">🔖</button>
                            </div>
                        </div> -->

                    </div>
                    <div class="trainer-info mobile-only">
                        <div class="trainer-avatar"></div>
                        <div>
                            <h3 class="trainer-name">Сергей Мейерхольд</h3>
                            <p class="trainer-title">Тренер по бариста</p>
                        </div>
                    </div>
                    <p class="trainer-description mobile-only">
                        Всем привет! Я около 10 лет работаю бариста, люблю кофе и являюсь химическим инженером.
                        Специализируюсь на еде.
                    </p>
                    <div class="coffee-image-placeholder mobile-only"></div>
                </div>
                <div class="sidebar-right desktop-only">
                    <div class="trainer-card">
                        <div class="trainer-info">
                            <div class="trainer-avatar"></div>
                            <div>
                                <h3 class="trainer-name">Сергей Мейерхольд</h3>
                                <p class="trainer-title">Тренер по бариста</p>
                            </div>
                        </div>
                        <p class="trainer-description">
                            Всем привет! Я около 10 лет работаю бариста, люблю кофе и являюсь химическим инженером.
                            Специализируюсь на еде.
                        </p>
                        <div class="coffee-image-placeholder"></div>
                        <p class="event-price">Бесплатно</p>
                        <p class="available-seats">Свободно 30 мест</p>
                        <div v-if="canParticipate" class="participate-button" @click="registerMemberToEvent">Участвовать
                        </div>
                        <div v-else class="participate-button-registered">Вы зарегистрированы</div>


                        <div class="share-link">
                            <input type="text" value="https://forma.ru/session/zagorayemna..." readonly
                                class="share-input" />
                            <button class="copy-button">Копировать</button>
                        </div>
                        <p class="participants">133/200 уже участвуют</p>
                    </div>
                </div>
            </main>
        </div>
    </div>
</template>

<script lang="ts">
/* eslint-disable */
import { defineComponent, onMounted, ref, watch, computed } from 'vue';
import axios from 'axios';
import VueJwtDecode from 'vue-jwt-decode';
import { useRoute } from 'vue-router';
import moment from 'moment';

import ReviewBlock from '../components/Review/ReviewBlock.vue';
import EventCardVue from "../components/EventCard.vue";
import LinkToMentor from '../components/Links/LinkToMentor.vue';
import CalendarSection from '../components/pages/personal/CalendarSection.vue'

export default defineComponent({
    name: 'EventPage',
    components: {
        ReviewBlock,
        EventCardVue,
        LinkToMentor,
        CalendarSection,
    },

    setup() {
        const route = useRoute();

        const events = ref<any[]>([]);
        const currentEvent = ref<any>({});
        const reviews = ref<any[]>([]);

        // var reviews = Array();
        const memberId = ref<any>(null);

        const canParticipate = computed(() => {
            console.log("logg: ", currentEvent.value &&
                currentEvent.value.event_members_id &&
                !(currentEvent.value.event_members_id.indexOf(memberId.value) >= 0));
            return currentEvent.value &&
                currentEvent.value.event_members_id &&
                !(currentEvent.value.event_members_id.indexOf(memberId.value) >= 0);
        });

        const hasEvent = computed(() => {
            const valid = currentEvent.value &&
                currentEvent.value.id &&
                Array.isArray(reviews.value)
            console.log("log: hasEvent is", valid);
            console.log("log: ", reviews.value);

            return valid;
        });


        /* eslint-disable */
        function formated(value: Date) {
            var some_date = moment(String(value.toLocaleString('ru-RU'))).format('DD MMMM hh:mm');
            some_date = some_date.replace("September", "Сентября");
            some_date = some_date.replace("October", "Октября");
            some_date = some_date.replace("November", "Ноября");
            some_date = some_date.replace("December", "Декабря");
            some_date = some_date.replace("January", "Января");
            some_date = some_date.replace("February", "Февраля");
            some_date = some_date.replace("March", "Марта");
            some_date = some_date.replace("April", "Апреля");
            some_date = some_date.replace("May", "Мая");
            some_date = some_date.replace("June", "Июня");
            some_date = some_date.replace("July", "Июля");
            some_date = some_date.replace("August", "Августа");
            return some_date;
        }

        async function fetchData() {
            try {
                const response = await axios.get('https://platforma34.tw1.ru/api/events/GetEvents/');
                events.value = response.data;
                console.log(response.data);
                console.log(events.value);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }

        async function fetchEvent() {
            try {
                const response = await axios.get('https://platforma34.tw1.ru/api/events/GetEvent/?id=' + route.params.id);
                currentEvent.value = response.data;
                console.log(response.data)
                console.log(currentEvent.value)
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }

        async function fetchReviews() {
            try {
                const response_reviews = await axios.get('https://platforma34.tw1.ru/api/event_reviews/GetEventReviews/?event_id=' + route.params.id);
                reviews.value = response_reviews.data;
                console.log(response_reviews.data)
                console.log(reviews.value)
                // console.log(this.items)
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }

        async function getIdFromToken() {
            memberId.value = await VueJwtDecode.decode(localStorage.getItem("accessToken")).user_id
        }

        async function checkMember() {
            if (await currentEvent.value.event_members_id.indexOf(memberId.value) >= 0) {
                console.log(memberId.value)
                return true;
            }
            return false;
        }

        async function registerMemberToEvent() {
            console.log(memberId.value);
            if (currentEvent.value.event_members_id.indexOf(memberId.value) >= 0) {
                return;
            } else {
                currentEvent.value.event_members_id.push(memberId.value);
            }
            try {
                const url = axios.put('https://platforma34.tw1.ru/api/events/UpdateEvent/?id=' + route.params.id, {
                    'event_members_id': currentEvent.value.event_members_id
                });
            } catch (e) {
                console.log("AN ERROR ACCURED" + e);
            }
            
            try {
                const url = await axios.get('https://platforma34.tw1.ru/api/users/GetUserByATID/?user_id=' + memberId)
                console.log("New url ", url);
                // var menti_registered_events ;
                // menti_registered_events.append(route.params.id);
                // const url = axios.put('https://platforma34.tw1.ru/api/users/UpdateUser/?id=' + memberId, {
                //     'menti_registered_events': menti_registered_events  
                // });
                // console.log(url);
            } catch (e) {
                console.log("AN ERROR ACCURED WHILE ADDING EVENT TO MENTI'S EVENTS LIST" + e);
            }
        }
        // watch(
        //     () => currentEvent.value,
        //     (newCurrentEvent) => {
        //         currentEvent.value = newCurrentEvent;
        //     }
        // );

        onMounted(async () => {
            fetchEvent();
            fetchData();
            fetchReviews();
            getIdFromToken();
        });
        return {
            registerMemberToEvent,
            // formated,
            currentEvent,
            events,
            memberId,
            canParticipate,
            hasEvent,
            reviews,
        };
    }
    // Add any necessary component logic here
})
</script>

<style scoped>
.event-page {
    margin: 0;
    padding: 0;
    width: 100%;
}

.header {
    background-color: #8A2BE2;
    color: white;
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 1000;
}

.logo {
    font-size: 1.5rem;
    font-weight: bold;
}

.nav {
    display: flex;
    align-items: center;
}

.select {
    background-color: #8A2BE2;
    color: white;
    border: none;
    padding: 0.5rem;
}

.search-container {
    position: relative;
}

.search-input {
    padding: 0.5rem 2rem 0.5rem 0.5rem;
    border-radius: 4px;
    border: none;
}

.search-icon {
    position: absolute;
    right: 0.5rem;
    top: 50%;
    transform: translateY(-50%);
}

.avatar {
    width: 2rem;
    height: 2rem;
    background-color: #ccc;
    border-radius: 50%;
}

.menu-button {
    background: none;
    border: none;
    color: white;
    font-size: 1.5rem;
    cursor: pointer;
}

.main-content {
    padding: 1rem;
}

.tags {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    margin-bottom: 1rem;
}

.tag {
    background-color: white;
    padding: 0.25rem 0.5rem;
    border-radius: 1rem;
    font-size: 0.875rem;
}

.title {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
}

.date {
    color: #666;
    margin-bottom: 1rem;
}

.event-image {
    width: 100%;
    border-radius: 8px;
    margin-bottom: 1rem;
}

.participation-info {
    margin-bottom: 1rem;
}

.event-price {
    font-weight: bold;
    margin-bottom: 0.5rem;
}

.available-seats {
    font-size: 0.875rem;
    color: #666;
    margin-bottom: 1rem;
}

.participate-button {
    width: 100%;
    background-color: #6553ca;
    color: white;
    border: none;
    padding: 0.75rem;
    border-radius: 4px;
    cursor: pointer;
    margin-bottom: 1rem;
}

.participate-button-registered {
    width: 100%;
    background-color: #7c7a84;
    color: white;
    border: none;
    padding: 0.75rem;
    border-radius: 4px;
    margin-bottom: 1rem;
}

.participate-button:hover {
    width: 100%;
    background-color: #454152;
    color: white;
    border: none;
    padding: 0.75rem;
    border-radius: 4px;
    cursor: pointer;
    margin-bottom: 1rem;
}

.share-link {
    display: flex;
    gap: 0.5rem;
    margin-bottom: 1rem;
}

.share-input {
    flex: 1;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 0.75rem;
}

.copy-button {
    background-color: white;
    border: 1px solid #ccc;
    padding: 0.5rem;
    border-radius: 4px;
    cursor: pointer;
    font-size: 0.75rem;
}

.participants {
    font-size: 0.875rem;
    color: #666;
    margin-bottom: 1rem;
}

.section-title {
    font-size: 1.25rem;
    margin-top: 1.5rem;
    margin-bottom: 1rem;
}

.topic-list {
    padding-left: 1.5rem;
    margin-bottom: 1.5rem;
}

.description {
    margin-bottom: 1.5rem;
}

.cancellation-policy {
    background-color: white;
    padding: 1rem;
    border-radius: 20px;
    margin-bottom: 1.5rem;
}

.policy-title {
    font-size: 1rem;
    margin-bottom: 0.5rem;
}

.policy-text {
    font-size: 0.875rem;
}

.trainer-info {
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;
}

.trainer-avatar {
    width: 4rem;
    height: 4rem;
    background-color: #ccc;
    border-radius: 50%;
}

.trainer-name {
    font-size: 1.125rem;
    margin-bottom: 0.25rem;
}

.trainer-title {
    font-size: 0.875rem;
    color: #666;
}

.trainer-description {
    font-size: 0.875rem;
    margin-bottom: 1rem;
}

.coffee-image-placeholder {
    width: 100%;
    height: 150px;
    background-color: #ccc;
    border-radius: 8px;
    margin-bottom: 1rem;
}

.mobile-only {
    display: block;
}

.desktop-only {
    display: none;
}

.card-list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 4rem 2rem;
    width: 100%;
    background-color: #454152 alpha 1;
    padding: 0;
    margin: 0;
}

@media (min-width: 768px) {
    .mobile-only {
        display: none;
    }

    .desktop-only {
        display: block;
    }

    .header {
        padding: 1rem 2rem;
    }

    .content {
        display: flex;
    }

    .sidebar {
        width: 4rem;
        background-color: #ffffff;
        border-right: 1px solid #e0e0e0;
        padding: 1rem 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1rem;
        position: fixed;
        top: 100px;
        bottom: 0;
        left: 0;
    }

    .sidebar-button {
        background: none;
        border: none;
        font-size: 1.5rem;
        cursor: pointer;
    }

    .main {
        display: flex;
        padding: 2rem;
        gap: 2rem;
    }

    .main-content {
        flex: 3;
        padding: 0;
        margin-left: 0px;
    }

    .title {
        font-size: 2rem;
    }

    .sidebar-right {
        flex: 1;
        position: sticky;
        top: 90px;
        align-self: flex-start;
        height: calc(100vh - 90px);
        overflow-y: auto;
    }

    .trainer-card {
        background-color: #f9f9f9;
        padding: 1rem;
        border-radius: 8px;
    }

    .reviews {
        margin-bottom: 2rem;
        width: auto;
        max-width: 65vw;
    }

    @media (min-width: 1800px) {
        .reviews {
            margin-bottom: 2rem;
            width: auto;
            max-width: 70vw;
        }
    }

    .add-review {
        color: #8A2BE2;
        font-size: 0.875rem;
        text-decoration: none;
        margin-left: 0.5rem;
    }

    .review {
        background-color: #f9f9f9;
        padding: 1rem;
        border-radius: 8px;
        margin-bottom: 1rem;
        display: flex;
        gap: 1rem;
    }

    .review-avatar {
        width: 3rem;
        height: 3rem;
        background-color: #ccc;
        border-radius: 50%;
    }

    .review-author {
        font-size: 1rem;
        margin-bottom: 0.5rem;
    }

    .review-text {
        font-size: 0.875rem;
    }

    .pagination {
        display: flex;
        justify-content: center;
        gap: 0.5rem;
    }

    .pagination-button {
        background: none;
        border: 1px solid #ccc;
        padding: 0.5rem;
        cursor: pointer;
    }

    .event-grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 1rem;
    }

    .event-image-placeholder {
        width: 100%;
        height: 150px;
        background-color: #ccc;
        border-radius: 8px;
        margin-bottom: 0.5rem;
    }

    .event-card-title {
        font-size: 1rem;
        margin-bottom: 0.5rem;
    }

    .event-card-author,
    .event-card-date {
        font-size: 0.875rem;
        color: #666;
        margin-bottom: 0.25rem;
    }

    .event-card-price {
        font-weight: bold;
        margin-bottom: 0.5rem;
    }

    .bookmark-button {
        background: none;
        border: none;
        cursor: pointer;
        font-size: 1.25rem;
    }
}

.masonry-container {
    display: flex;
    /* Flexbox to create columns */
    gap: 2vw;
    /* Space between columns */

}

/* Each column */
.masonry-column {
    flex: 1;
    /* Each column takes up an equal share of the container */
    display: flex;
    flex-direction: column;
    /* Stack items vertically */
    gap: 3vw;
    /* Space between items within each column */
    max-width: calc((100vw - 16vw - 2*2vw)/3);
}

</style>