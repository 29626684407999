<template>
    <div class="education-block" ref="blockRef">
      <div class="education-header">
        <h2>{{ title }}</h2>
        <span class="items-count">{{ items.length }}</span>
      </div>
  
      <div class="education-content" :style="contentStyle" ref="contentRef">
        <div v-for="(item, index) in items" :key="index" class="education-item" ref="itemRefs">
          <div class="program">{{ item.program }}</div>
          <div class="institution">{{ item.institution }} / {{ item.years }}</div>
        </div>
      </div>
  
      <button v-if="items.length > initialItemsCount" @click="toggleExpand" class="show-more-btn">
        {{ isExpanded ? 'Скрыть' : 'Показать всё' }}
      </button>
    </div>
  </template>
  
  <script lang="ts">
  import { defineComponent, ref, computed, PropType, onMounted, watch, nextTick } from 'vue'
  
  interface EducationItem {
    program: string;
    institution: string;
    years: string;
  }
  
  export default defineComponent({
    name: 'EducationBlock',
    props: {
      title: {
        type: String,
        default: 'Образование'
      },
      items: {
        type: Array as PropType<EducationItem[]>,
        default: () => ([
          {
            program: 'Технология аналитического контроля химических соединений',
            institution: 'РАНХиГС',
            years: '2011 - 2014'
          },
          {
            program: 'Химическая технология органических веществ',
            institution: 'МГУ',
            years: '2014 - 2016'
          }
        ])
      },
      initialItemsCount: {
        type: Number,
        default: 1
      }
    },
    setup(props) {
      const isExpanded = ref(false);
      const blockRef = ref<HTMLElement | null>(null);
      const contentRef = ref<HTMLElement | null>(null);
      const itemRefs = ref<HTMLElement[]>([]);
      const contentHeight = ref(0);
      const initialContentHeight = ref(0);
  
      const toggleExpand = () => {
        isExpanded.value = !isExpanded.value;
      };
  
      const contentStyle = computed(() => ({
        maxHeight: isExpanded.value ? `${contentHeight.value}px` : `${initialContentHeight.value}px`,
        overflow: 'hidden',
        transition: 'max-height 0.5s ease-in-out'
      }));
  
      const updateContentHeight = () => {
        nextTick(() => {
          if (contentRef.value && itemRefs.value.length > 0) {
            contentHeight.value = contentRef.value.scrollHeight;
            
            // Calculate initial content height based on actual item heights
            initialContentHeight.value = itemRefs.value
              .slice(0, props.initialItemsCount)
              .reduce((total, item) => total + item.offsetHeight, 0);
          }
        });
      };
  
      onMounted(() => {
        updateContentHeight();
        window.addEventListener('resize', updateContentHeight);
      });
  
      watch(() => props.items, updateContentHeight, { deep: true });
  
      return {
        isExpanded,
        toggleExpand,
        contentStyle,
        blockRef,
        contentRef,
        itemRefs
      };
    },
  })
  </script>
  
  <style scoped>
  .education-block {
    background-color: white;
    border-radius: 12px;
    padding: 24px;
    display: flex;
    height: auto;
    flex-direction: column;
  }
  
  .education-header {
    font-size: 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .education-header h2 {
    font-size: 32px;
    font-weight: 600;
    color: #333;
    margin: 0;
  }
  
  .items-count {
    background: #4a5568;
    color: white;
    padding: 2px 8px;
    border-radius: 4px;
    font-size: 14px;
  }
  
  .education-item {
    margin-bottom: 20px;
  }
  
  .education-item:last-child {
    margin-bottom: 0;
  }
  
  .program {
    font-size: 24px;
    font-weight: 600;
    color: #333;
    margin-bottom: 4px;
  }
  
  .institution {
    font-size: 18px;
    color: #666;
  }
  
  .show-more-btn {
    background: none;
    border: none;
    color: #5850ec;
    font-size: 14px;
    cursor: pointer;
    padding: 0;
    margin-top: 10px;
    transition: color 0.3s ease;
  }
  
  .show-more-btn:hover {
    color: #4338ca;
  }
  </style>
  
  