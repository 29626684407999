<template>
    <div class="description-block" ref="blockRef">
      <div class="description-header">
        <h2>Обо мне</h2>
      </div>
  
      <div class="description-content" :style="contentStyle" ref="contentRef">
        <div class="description-text" :class="{ 'truncated': !isExpanded }">
          {{ description }}
        </div>
      </div>
  
      <button v-if="description.length > 150" @click="toggleExpand" class="show-more-btn">
        {{ isExpanded ? 'Скрыть' : 'Показать всё' }}
      </button>
    </div>
  </template>
  
  <script lang="ts">
  import { defineComponent, ref, computed, onMounted, watch, nextTick } from 'vue'
  
  export default defineComponent({
    name: 'DescriptionComponent',
    props: {
    //   title: {
    //     type: String,
    //     default: 'Обо мне'
    //   },
      description: {
        type: String,
        required: true
      }
    },
    setup(props) {
      const isExpanded = ref(false);
      const blockRef = ref<HTMLElement | null>(null);
      const contentRef = ref<HTMLElement | null>(null);
      const contentHeight = ref(0);
      const initialContentHeight = ref(0);
  
      const toggleExpand = () => {
        isExpanded.value = !isExpanded.value;
        updateContentHeight();
      };
  
      const contentStyle = computed(() => ({
        maxHeight: isExpanded.value ? `${contentHeight.value}px` : `${initialContentHeight.value}px`,
        overflow: 'hidden',
        transition: 'max-height 0.5s ease-in-out'
      }));
  
      const updateContentHeight = () => {
        nextTick(() => {
          if (contentRef.value) {
            contentHeight.value = contentRef.value.scrollHeight;
            initialContentHeight.value = 80; // Approximately 3 lines of text
          }
        });
      };
  
      onMounted(() => {
        updateContentHeight();
        window.addEventListener('resize', updateContentHeight);
      });
  
      watch(() => props.description, updateContentHeight);
  
      return {
        isExpanded,
        toggleExpand,
        contentStyle,
        blockRef,
        contentRef
      };
    },
  })
  </script>
  
  <style scoped>
  .description-block {
    background-color: white;
    border-radius: 12px;
    padding: 24px;
    display: flex;
    flex-direction: column;
    height: auto;
  }
  
  .description-header {
    font-size: 32px;
    margin-bottom: 20px;
  }
  
  .description-header h2 {
    font-size: 32px;
    font-weight: 600;
    color: #333;
    margin: 0;
  }
  
  .description-content {
    flex-grow: 1;
  }
  
  .description-text {
    font-size: 18px;
    color: #454152;
    line-height: 1.5;
  }
  
  .description-text.truncated {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  
  .show-more-btn {
    background: none;
    border: none;
    color: #5850ec;
    font-size: 14px;
    cursor: pointer;
    padding: 0;
    margin-top: 10px;
    transition: color 0.3s ease;
  }
  
  .show-more-btn:hover {
    color: #4338ca;
  }
  </style>