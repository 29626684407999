<template>
    <!-- <div class="mentors-page"> -->
    <div class="mentors-content">
        <div class="mentors-list">
            <MentorCard v-for="mentor in mentors" :key="mentor.id" :mentor="mentor" @toggle-like="toggleLike" />
        </div>
        <aside class="filters-sidebar">
        <div class="search-container">
          <input 
            type="text" 
            placeholder="Поиск" 
            class="search-input"
            v-model="searchQuery"
          />
        </div>
        
        <div class="categories">
          <button 
            v-for="category in categories" 
            :key="category"
            class="category-tag"
            :class="{ active: selectedCategories.includes(category) }"
            @click="toggleCategory(category)"
          >
            {{ category }}
          </button>
        </div>

        <div class="price-filter">
          <h3>Стоимость</h3>
          <div class="price-inputs">
            <select v-model="minPrice" class="price-select">
              <option value="300">300 руб</option>
              <option value="500">500 руб</option>
              <option value="1000">1000 руб</option>
            </select>
            <select v-model="maxPrice" class="price-select">
              <option value="500">500 руб</option>
              <option value="1000">1000 руб</option>
              <option value="2000">2000 руб</option>
            </select>
          </div>
        </div>

        <div class="sorting">
          <select v-model="sortingOption" class="sorting-select">
            <option value="">Без сортировки</option>
            <option value="price-asc">По возрастанию цены</option>
            <option value="price-desc">По убыванию цены</option>
            <option value="popular">По популярности</option>
          </select>
        </div>
      </aside>
    </div>
    <!-- </div> -->
</template>

<script setup>
import { ref } from 'vue';
import MentorCard from '../components/pages/mentors/MentorCard.vue';

// Sample data
const mentors = ref([
    {
        id: 1,
        name: 'Сергей Мейерхольд',
        position: 'Главный технический писатель и редактор в Газета.ру',
        description: 'Всем привет. Я около 10 лет работаю бариста, люблю кофе и являюсь химическим инженером. Специализируюсь на еде..',
        price: '11 000 ₽',
        likes: 1666,
        tags: ['E-commerce', 'FashionTech и мода', 'IT', 'Маркетинг и реклама', 'СМИ и медиа'],
        image: 'https://avatars.dzeninfra.ru/get-zen_doc/1860870/pub_630be06b4adab22ce4602f20_630be0af5847ad612e927127/scale_1200'
    },
    {
        id: 2,
        name: 'Сергей Мейерхольд',
        position: 'Главный технический писатель и редактор в Газета.ру',
        description: 'Всем привет. Я около 10 лет работаю бариста, люблю кофе и являюсь химическим инженером. Специализируюсь на еде..',
        price: '11 000 ₽',
        likes: 1666,
        tags: ['E-commerce', 'FashionTech и мода', 'IT', 'Маркетинг и реклама', 'СМИ и медиа'],
        image: 'https://avatars.dzeninfra.ru/get-zen_doc/1860870/pub_630be06b4adab22ce4602f20_630be0af5847ad612e927127/scale_1200'
    },
    {
        id: 3,
        name: 'Сергей Мейерхольд',
        position: 'Главный технический писатель и редактор в Газета.ру',
        description: 'Всем привет. Я около 10 лет работаю бариста, люблю кофе и являюсь химическим инженером. Специализируюсь на еде..',
        price: '11 000 ₽',
        likes: 1666,
        tags: ['E-commerce', 'FashionTech и мода', 'IT', 'Маркетинг и реклама', 'СМИ и медиа'],
        image: 'https://avatars.dzeninfra.ru/get-zen_doc/1860870/pub_630be06b4adab22ce4602f20_630be0af5847ad612e927127/scale_1200'
    },
    {
        id: 4,
        name: 'Сергей Мейерхольд',
        position: 'Главный технический писатель и редактор в Газета.ру',
        description: 'Всем привет. Я около 10 лет работаю бариста, люблю кофе и являюсь химическим инженером. Специализируюсь на еде..',
        price: '11 000 ₽',
        likes: 1666,
        tags: ['E-commerce', 'FashionTech и мода', 'IT', 'Маркетинг и реклама', 'СМИ и медиа'],
        image: 'https://avatars.dzeninfra.ru/get-zen_doc/1860870/pub_630be06b4adab22ce4602f20_630be0af5847ad612e927127/scale_1200'
    },
    {
        id: 5,
        name: 'Сергей Мейерхольд',
        position: 'Главный технический писатель и редактор в Газета.ру',
        description: 'Всем привет. Я около 10 лет работаю бариста, люблю кофе и являюсь химическим инженером. Специализируюсь на еде..',
        price: '11 000 ₽',
        likes: 1666,
        tags: ['E-commerce', 'FashionTech и мода', 'IT', 'Маркетинг и реклама', 'СМИ и медиа'],
        image: 'https://avatars.dzeninfra.ru/get-zen_doc/1860870/pub_630be06b4adab22ce4602f20_630be0af5847ad612e927127/scale_1200'
    },
    {
        id: 6,
        name: 'Сергей Мейерхольд',
        position: 'Главный технический писатель и редактор в Газета.ру',
        description: 'Всем привет. Я около 10 лет работаю бариста, люблю кофе и являюсь химическим инженером. Специализируюсь на еде..',
        price: '11 000 ₽',
        likes: 1666,
        tags: ['E-commerce', 'FashionTech и мода', 'IT', 'Маркетинг и реклама', 'СМИ и медиа'],
        image: 'https://avatars.dzeninfra.ru/get-zen_doc/1860870/pub_630be06b4adab22ce4602f20_630be0af5847ad612e927127/scale_1200'
    },
    {
        id: 7,
        mentor_name: 'Сергей Мейерхольд',
        position: 'Главный технический писатель и редактор в Газета.ру',
        description: 'Всем привет. Я около 10 лет работаю бариста, люблю кофе и являюсь химическим инженером. Специализируюсь на еде..',
        price: '11 000 ₽',
        likes: 1666,
        tags: ['E-commerce', 'FashionTech и мода', 'IT', 'Маркетинг и реклама', 'СМИ и медиа'],
        image: 'https://avatars.dzeninfra.ru/get-zen_doc/1860870/pub_630be06b4adab22ce4602f20_630be0af5847ad612e927127/scale_1200'
    },
    {
        id: 8,
        name: 'Сергей Мейерхольд',
        position: 'Главный технический писатель и редактор в Газета.ру',
        description: 'Всем привет. Я около 10 лет работаю бариста, люблю кофе и являюсь химическим инженером. Специализируюсь на еде..',
        price: '11 000 ₽',
        likes: 1666,
        tags: ['E-commerce', 'FashionTech и мода', 'IT', 'Маркетинг и реклама', 'СМИ и медиа'],
        image: 'https://avatars.dzeninfra.ru/get-zen_doc/1860870/pub_630be06b4adab22ce4602f20_630be0af5847ad612e927127/scale_1200'
    },

]);

const categories = ref([
    'Здоровье',
    'Карьера',
    'Красота',
    'Образование',
    'Просвещение',
    'Хобби'
]);

const searchQuery = ref('');
const selectedCategories = ref([]);
const minPrice = ref('300');
const maxPrice = ref('500');
const sortingOption = ref('');

const toggleCategory = (category) => {
    const index = selectedCategories.value.indexOf(category);
    if (index === -1) {
        selectedCategories.value.push(category);
    } else {
        selectedCategories.value.splice(index, 1);
    }
};

const toggleLike = (mentorId) => {
    const mentor = mentors.value.find(m => m.id === mentorId);
    if (mentor) {
        mentor.isLiked = !mentor.isLiked;
    }
};
</script>

<style scoped>
/* .mentors-page {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
} */

.mentors-content {
    display: flex;
    gap: 40px;
}

.mentors-list {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.filters-sidebar {
  width: 300px;
  padding: 20px;
  background: #FFFFFF;
  border-radius: 10px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 80px;
  height: 350px;
  overflow-y: auto;
}

.search-container {
  margin-bottom: 30px;
}

.search-input {
  width: 100%;
  padding: 10px 15px;
  border: 1px solid #D5D7DC;
  border-radius: 8px;
  font-size: 16px;
}

.categories {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 30px;
}

.category-tag {
  padding: 6px 12px;
  border: 1px solid #D5D7DC;
  border-radius: 20px;
  background: none;
  cursor: pointer;
  font-size: 14px;
  color: #434966;
}

.category-tag.active {
  background: #7B65F7;
  color: white;
  border-color: #7B65F7;
}

.price-filter {
  margin-bottom: 30px;
}

.price-filter h3 {
  font-size: 18px;
  color: #2A2037;
  margin-bottom: 15px;
}

.price-inputs {
  display: flex;
  gap: 10px;
}

.price-select,
.sorting-select {
  width: 100%;
  padding: 8px;
  border: 1px solid #D5D7DC;
  border-radius: 8px;
  background: white;
  color: #434966;
}

.sorting {
  margin-top: 20px;
}
</style>
