<template>
    <div class="page-container">
        <!-- Left Sidebar
        <aside v-if="!isMobile" class="sidebar-left hidden md:block">
            <nav class="nav-menu">
                <button class="nav-item">
                    <PlusIcon class="icon" />
                </button>
                <button class="nav-item active">
                    <CalendarIcon class="icon" />
                </button>
                <button class="nav-item">
                    <MessageSquareIcon class="icon" />
                </button>
            </nav>
        </aside> -->

        <!-- Main Content -->
        <main class="main-content">
            <header class="page-header">
                <h1>Календарь</h1>
                <div class="search-bar hidden md:block">
                    <SearchIcon class="search-icon" />
                    <input type="text" placeholder="Искать менторов" />
                </div>
                <div class="user-notifications">
                    <BellIcon class="icon" />
                    <span class="notification-badge">2</span>
                </div>
            </header>

            <div class="tabs">
                <button v-for="tab in tabs" :key="tab.id" :class="['tab-button', { active: currentTab === tab.id }]"
                    @click="currentTab = tab.id; console.log(pendingEvents)">
                    {{ tab.name }}
                </button>
            </div>

            <!-- Mobile Calendar View -->
            <div v-if="isMobile && currentTab === 'schedule'" class="mobile-calendar">
                <CalendarSection class="calendar-sectionn" />
                <!-- <div class="calendar-header">
                    <span>27 Октября - 02 Ноября</span>
                </div>
                <VCalendar :attributes="calendarAttributes" :min-date="new Date()" :first-day-of-week="1"
                    class="custom-calendar" />
                <div class="time-slots">
                    <div v-for="slot in timeSlots" :key="slot.id" class="time-slot">
                        <div class="time-slot-info">
                            <div class="time-slot-date">
                                {{ slot.date }}
                                <span class="time-slot-day">{{ slot.day }}</span>
                            </div>
                            <div>
                                <div class="time-slot-time">{{ slot.time }}</div>
                                <div class="time-slot-duration">{{ slot.duration }}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <button class="btn-schedule">
                    <StarIcon class="icon" />
                    Настроить график доступности
                </button> -->
            </div>

            <!-- Events List -->
            <div>
                <TransitionGroup name="list" tag="div" class="events-list">
                    <div v-if="currentTab === 'upcoming'">
                        <div v-for="event in upcomingEvents" :key="event.id" class="event-card">
                            <div class="event-header">
                                <h3>{{ event.title }}</h3>
                                <span v-if="event.status === 'live'" class="live-badge">
                                    <CircleIcon class="icon" />
                                    В эфире через {{ event.startIn }}
                                </span>
                            </div>
                            <div class="event-time" v-if="event.datetime">
                                {{ formatDate(event.datetime) }}
                            </div>
                            <div class="event-actions">
                                <button v-if="event.status === 'live'" class="btn-primary">
                                    Присоединиться
                                </button>
                                <button v-else class="btn-secondary">
                                    Отменить запись
                                </button>
                                <button class="btn-text">
                                    {{ event.status === 'live' ? 'Отменить запись' : 'Подробнее' }}
                                </button>
                            </div>
                        </div>
                    </div>

                    <div v-if="currentTab === 'pending'">
                        <div v-for="event in pendingEvents" :key="event.id" class="event-card pending">
                            <div class="event-header">
                                <h3>{{ event.title }}</h3>
                                <span class="pending-badge">На рассмотрении</span>
                            </div>
                            <div class="event-time">
                                {{ formatDate(event.datetime) }}
                            </div>
                            <div class="event-actions">
                                <button class="btn-secondary">Отменить запись</button>
                                <button class="btn-text">Подробнее</button>
                            </div>
                        </div>
                    </div>

                    <div v-if="currentTab === 'history'">
                        <div v-for="event in historyEvents" :key="event.id" class="event-card history">
                            <div class="event-header">
                                <h3>Стандартное название мероприятия</h3>
                            </div>
                            <div class="event-time">
                                {{ formatDate(event.datetime) }}
                            </div>
                            <div class="event-actions">
                                <button class="btn-secondary">Искать похожие</button>
                                <button class="btn-text">Детали</button>
                            </div>
                        </div>
                    </div>
                </TransitionGroup>
            </div>

            <!-- Mobile Navigation -->
            <nav v-if="isMobile" class="mobile-nav">
                <button v-for="item in navigationItems" :key="item.id"
                    :class="['mobile-nav-item', { active: currentTab === item.id }]" @click="currentTab = item.id">
                    <component :is="item.icon" class="icon" />
                    <span>{{ item.name }}</span>
                </button>
            </nav>
        </main>

        <!-- Right Sidebar -->
        <aside v-if="!isMobile" class="sidebar-right">
            <CalendarSection class="calendar-sectionn" />
            <!-- <div class="calendar-wrapper">
                <VCalendar :attributes="calendarAttributes" :min-date="new Date()" :first-day-of-week="1"
                    class="custom-calendar" />
                <div class="time-slots">
                    <div v-for="slot in timeSlots" :key="slot.id" class="time-slot">
                        <div class="time-slot-date">
                            {{ slot.date }}
                            <span class="time-slot-day">{{ slot.day }}</span>
                        </div>
                        <div class="time-slot-time">{{ slot.time }}</div>
                        <div class="time-slot-duration">{{ slot.duration }}</div>
                    </div>
                </div>
                <button class="btn-schedule">
                    <ClockIcon class="icon" />
                    Настроить график доступности
                </button>
            </div> -->
        </aside>
    </div>
</template>

<script lang="ts">
/* eslint-disable */
import { ref, computed, defineComponent, onMounted } from 'vue'
import { Calendar as VCalendar } from 'v-calendar'
import { useWindowSize } from '@vueuse/core'
import axios from 'axios'
import {
    PlusIcon,
    CalendarIcon,
    MessageSquareIcon,
    // SearchIcon,
    // BellIcon,
    CircleIcon,
    ClockIcon,
    // StarIcon,
    // HomeIcon
} from 'lucide-vue-next'


import VueJwtDecode from 'vue-jwt-decode';
import CalendarSection from '../components/pages/personal/CalendarSection.vue'

export default defineComponent({
    name: 'CalendarPage',
    components: {
        CalendarSection,
        PlusIcon,
        CalendarIcon,
        MessageSquareIcon,
        CircleIcon,
        ClockIcon,
        VCalendar
    },
    setup() {
        /* eslint-disable */

        // interface Event {
        //     id: number;
        //     title: string;
        //     datetime: Date;
        //     status: 'live' | 'upcoming' | 'pending' | 'completed';
        //     startIn?: string;
        // }

        const currentTab = ref('upcoming')
        const { width } = useWindowSize()
        const isMobile = computed(() => width.value < 768)

        const tabs = computed(() => [
            ...(isMobile.value ? [{ id: 'schedule', name: 'График' }] : []),
            { id: 'upcoming', name: 'Предстоящие' },
            { id: 'pending', name: 'На одобрении' },
            { id: 'history', name: 'История' }
        ])

        const navigationItems = [
            { id: 'schedule', name: 'График', icon: CalendarIcon },
            { id: 'upcoming', name: 'События', icon: MessageSquareIcon },
            { id: 'profile', name: 'Профиль', icon: PlusIcon }
        ]

        // const events = ref<Event[]>([
        //     {
        //         id: 1,
        //         title: 'Загораем на солнце правильно. Без ожогов и вреда для кожи',
        //         datetime: new Date(),
        //         status: 'live',
        //         startIn: '5 минут'
        //     },
        //     {
        //         id: 2,
        //         title: 'Загораем на солнце правильно. Без ожогов и вреда для кожи',
        //         datetime: new Date(new Date().setDate(new Date().getDate() + 1)),
        //         status: 'upcoming'
        //     },
        //     {
        //         id: 3,
        //         title: 'Загораем на солнце правильно. Без ожогов и вреда для кожи',
        //         datetime: new Date(new Date().setDate(new Date().getDate() + 2)),
        //         status: 'pending'
        //     },
        //     {
        //         id: 4,
        //         title: 'Загораем на солнце правильно. Без ожогов и вреда для кожи',
        //         datetime: new Date(new Date().setDate(new Date().getDate() - 7)),
        //         status: 'completed'
        //     }
        // ])

        const events = ref<any[]>([])

        const userId = ref<any>(null);



        async function getIdFromToken() {
            // userId.value = await VueJwtDecode.decode(localStorage.getItem("accessToken")).user_id
            console.log("UserId IS: ", userId.value)
        }

        async function getUserEvents() {
            await getIdFromToken()
            // events.value = await axios.get('https://platforma34.tw1.ru/api/events/GetUserEvents/?user_id='+userId.value);
            const events_response = await axios.get('https://platforma34.tw1.ru/api/events/GetUserEvents/?user_id=45')
            events.value = events_response.data
            console.log("EVENTS ARE:", events.value)
            if (Array.isArray(events.value)) {
                events.value.sort((a, b) => {
                    return new Date(a.event_date).getTime() - new Date(b.event_date).getTime();
                });
            }
            // events.value.sort((a, b) => {
            //     return new Date(a.event_date).getTime() - new Date(b.event_date).getTime();
            // });
            console.log("EVENTS ARE:", events.value)
        }

        const timeSlots = ref([
            { id: 1, date: '30', day: 'ПН', time: '12:00', duration: '1 час' },
            { id: 2, date: '30', day: 'ПН', time: '15:00', duration: '1 час' }
        ])

        const calendarAttributes = ref([
            {
                highlight: true,
                dates: new Date()
            }
        ])

        const isEventInTenMinutes = (eventDate: string): boolean => {
            const eventTime = new Date(eventDate).getTime();
            const now = Date.now();
            const tenMinutesInMilliseconds = 10 * 60 * 1000;

            return eventTime - now <= tenMinutesInMilliseconds && eventTime > now;
        };

        const isEventStartedLessThanAnHourAgo = (eventDate: string): boolean => {
            const now = new Date().getTime();
            const eventTime = new Date(eventDate).getTime();
            const oneHourInMilliseconds = 60 * 60 * 1000;

            return now - eventTime <= oneHourInMilliseconds && now > eventTime;
        };

        const upcomingEvents = computed(() => {
            const now = new Date();
            return events.value.filter(event => new Date(event.event_date) > now);
        });



        const pendingEvents = computed(() =>

            events.value.filter(event => event.event_canceled == "t")
        )



        const historyEvents = computed(() =>
            events.value.filter(event => {
                const now = new Date().getTime();
                const eventTime = new Date(event.event_date).getTime();
                const oneHourInMilliseconds = 60 * 60 * 1000;
                return now - eventTime > oneHourInMilliseconds;
            })

            // events.value.filter(event => event.status === 'completed')
        )

        const formatDate = (date: Date): string => {
            return new Intl.DateTimeFormat('ru-RU', {
                day: 'numeric',
                month: 'long',
                hour: '2-digit',
                minute: '2-digit'
            }).format(date)
        }

        onMounted(() => {
            getUserEvents();
        })

        return {
            formatDate,
            historyEvents,
            pendingEvents,
            upcomingEvents,
            calendarAttributes,
            timeSlots,
            events,
            navigationItems,
            tabs,
            currentTab,
            isMobile
        }
    }
});

</script>

<style scoped>
.page-container {
    min-height: 100vh;
    font-family: 'Inter', sans-serif;
}

.sidebar-left {
    margin-top: 80px;
    position: fixed;
    left: 0;
    top: 0;
    width: 44px;
    height: 100vh;
    padding: 24px 16px;
    background: #f3f4f5;
    border-right: 1px solid #E5E7EB;
    display: flex;
    flex-direction: column;
    z-index: 10;
}

.nav-menu {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.nav-item {
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 12px;
    border-radius: 8px;
    border: none;
    background: transparent;
    cursor: pointer;
    font-size: 14px;
    color: #6B7280;
}

.nav-item:hover {
    background: #F3F4F6;
}

.nav-item.active {
    background: #7C3AED;
    color: white;
}

.main-content {
    margin-left: 28px;
    margin-right: 20rem;
    padding: 24px;
}

.page-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
    gap: 10px;
}

.page-header h1 {
    font-size: 24px;
    font-weight: 600;
    color: #111827;
}

.search-bar {
    position: relative;
    width: 320px;
}

.search-bar input {
    width: calc(100% - 52px);
    padding: 8px 12px 8px 40px;
    border: 1px solid #E5E7EB;
    border-radius: 8px;
    font-size: 14px;
    transition: all 0.3s ease;
}

.search-bar input:focus {
    border-color: #4F46E5;
    box-shadow: 0 0 4px 2px rgba(79, 70, 229, 0.5);
    outline: none;
}

.search-icon {
    position: absolute;
    left: 12px;
    top: 50%;
    transform: translateY(-50%);
    color: #9CA3AF;
}

.user-notifications {
    position: relative;
}

.notification-badge {
    position: absolute;
    top: -5px;
    right: -5px;
    background: #EF4444;
    color: white;
    font-size: 10px;
    padding: 2px 6px;
    border-radius: 10px;
}

.tabs {
    display: flex;
    gap: 24px;
    margin-bottom: 24px;
    border-bottom: 1px solid #E5E7EB;
}

.tab-button {
    padding: 12px 0;
    border: none;
    background: transparent;
    cursor: pointer;
    color: #6B7280;
    font-size: 14px;
    font-weight: 500;
}

.tab-button.active {
    color: #7C3AED;
    border-bottom: 2px solid #7C3AED;
}

.events-list {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.event-card {
    background: white;
    border-radius: 8px 8px 8px 8px;
    border: 1px solid gray;
    padding: 16px;
    margin-top: 8px;
    margin-bottom: 8px;
}

.event-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 8px;
}

.event-header h3 {
    font-size: 16px;
    font-weight: 600;
    color: #111827;
}

.live-badge {
    display: flex;
    align-items: center;
    gap: 4px;
    background: #7C3AED;
    color: white;
    padding: 4px 8px;
    border-radius: 16px;
    font-size: 12px;
}

.pending-badge {
    background: #FEF3C7;
    color: #D97706;
    padding: 4px 8px;
    border-radius: 16px;
    font-size: 12px;
}

.event-time {
    color: #6B7280;
    font-size: 14px;
    margin-bottom: 16px;
}

.event-actions {
    display: flex;
    gap: 8px;
}

.btn-primary {
    background: #7C3AED;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
}

.btn-secondary {
    background: white;
    border: 1px solid #7C3AED;
    color: #7C3AED;
    padding: 8px 16px;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
}

.btn-text {
    background: transparent;
    border: none;
    color: #6B7280;
    padding: 8px 16px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
}

.sidebar-right {
    margin-top: 80px;
    position: fixed;
    right: 0;
    top: 0;
    width: 18rem;
    height: 80%;
    z-index: 10;
    overflow-y: auto;
    /* Добавляем вертикальную прокрутку */
    overflow-x: clip;
    /* Убираем горизонтальную прокрутку, если она не нужна */
    padding-right: 60px;
    margin-left: 20px;
}

.calendar-wrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.custom-calendar {
    --day-border: 1px solid #E5E7EB;
    --day-border-highlight: 1px solid #7C3AED;
    --day-width: 40px;
    --day-height: 40px;
    --day-content-height: 40px;
    --day-content-width: 40px;
}

.time-slots {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.time-slot {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px;
    background: #F3F4F6;
    border-radius: 8px;
}

.time-slot-date {
    font-weight: 600;
    font-size: 16px;
    color: #111827;
}

.time-slot-day {
    color: #6B7280;
    font-size: 12px;
}

.time-slot-time {
    color: #111827;
    font-size: 14px;
}

.time-slot-duration {
    color: #6B7280;
    font-size: 12px;
}

.btn-schedule {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    color: #7C3AED;
    background: transparent;
    border: none;
    padding: 8px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
}

.icon {
    width: 20px;
    height: 20px;
}

.tab-button {
    position: relative;
}

.tab-button::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background: #7C3AED;
    transform: scaleX(0);
    transition: transform 0.3s ease;
}

.tab-button.active::after {
    transform: scaleX(1);
}

/* Mobile styles */
@media (max-width: 767px) {
    .page-container {
        display: flex;
        flex-direction: column;
    }

    .main-content {
        margin-left: 0;
        margin-right: 0;
        padding: 16px;
        padding-bottom: 80px;
    }

    .page-header {
        margin-bottom: 16px;
    }

    .mobile-nav {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        background: white;
        display: flex;
        justify-content: space-around;
        padding: 8px 16px;
        border-top: 1px solid #E5E7EB;
        z-index: 50;
    }

    .mobile-nav-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 4px;
        color: #6B7280;
        background: transparent;
        border: none;
        font-size: 12px;
    }

    .mobile-nav-item.active {
        color: #7C3AED;
    }

    .mobile-calendar {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: center;
    }

    .calendar-sectionn {
        width: calc(100%);
        padding-bottom: 16px;
        /* Добавляем внутренние отступы, чтобы не было наложения */
        box-shadow: none;
        align-items: center;
    }

    .calendar-header {
        text-align: center;
        font-weight: 600;
        color: #111827;
    }

    .time-slots {
        padding: 0 16px;
    }

    .time-slot {
        background: #F9FAFB;
        border-radius: 12px;
        padding: 16px;
        margin-bottom: 8px;
    }

    .time-slot-info {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .btn-schedule {
        width: 100%;
        padding: 16px;
        font-size: 14px;
    }

    .tabs {
        overflow-x: auto;
        white-space: nowrap;
        -webkit-overflow-scrolling: touch;
        scrollbar-width: none;
        -ms-overflow-style: none;
    }

    .tabs::-webkit-scrollbar {
        display: none;
    }

    .tab-button {
        display: inline-block;
    }
}
</style>