import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, Teleport as _Teleport, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  class: "description-block",
  ref: "blockRef"
}
const _hoisted_2 = {
  class: "description-content",
  ref: "contentRef"
}
const _hoisted_3 = { class: "description-text" }
const _hoisted_4 = { class: "description-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[4] || (_cache[4] = _createElementVNode("div", { class: "description-header" }, [
      _createElementVNode("h2", null, "Обо мне")
    ], -1)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.truncatedDescription), 1)
    ], 512),
    (_ctx.description.length > 250)
      ? (_openBlock(), _createElementBlock("button", {
          key: 0,
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.openModal && _ctx.openModal(...args))),
          class: "show-more-btn"
        }, " Показать всё "))
      : _createCommentVNode("", true),
    (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
      _createVNode(_Transition, { name: "modal" }, {
        default: _withCtx(() => [
          (_ctx.isModalOpen)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: "modal-overlay",
                onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.closeModal && _ctx.closeModal(...args)))
              }, [
                _createElementVNode("div", {
                  class: "modal-content platforma-font",
                  onClick: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["stop"]))
                }, [
                  _createElementVNode("button", {
                    onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.closeModal && _ctx.closeModal(...args))),
                    class: "close-btn"
                  }, "×"),
                  _createElementVNode("h2", null, _toDisplayString(_ctx.title), 1),
                  _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.description), 1)
                ])
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]))
  ], 512))
}