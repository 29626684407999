<template>
    <div class="education-block" ref="blockRef">
        <div class="education-header">
            <h2>{{ title }}</h2>
            <span class="items-count">{{ items.length }}</span>
        </div>

        <div class="education-content" ref="contentRef">
            <div v-for="(item, index) in visibleItems" :key="index" class="education-item" ref="itemRefs">
                <div class="program">{{ item.program }}</div>
                <div class="institution">{{ item.institution }} / {{ item.years }}</div>
            </div>
        </div>

        <button v-if="items.length > initialItemsCount" @click="openModal" class="show-more-btn">
            Показать всё
        </button>

        <teleport to="body">
            <transition name="modal">
                <div v-if="isModalOpen" class="modal-overlay" @click="closeModal">
                    <div class="modal-content platforma-font" @click.stop>
                        <button @click="closeModal" class="close-btn">&times;</button>
                        <h2>{{ title }}</h2>
                        <div v-for="(item, index) in items" :key="index" class="education-item">
                            <div class="program">{{ item.program }}</div>
                            <div class="institution">{{ item.institution }} / {{ item.years }}</div>
                        </div>
                    </div>
                </div>
            </transition>
        </teleport>
    </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed, PropType } from 'vue'

interface EducationItem {
    program: string;
    institution: string;
    years: string;
}

export default defineComponent({
    name: 'EducationBlockDesktop',
    props: {
        title: {
            type: String,
            default: 'Образование'
        },
        items: {
            type: Array as PropType<EducationItem[]>,
            default: () => ([])
        },
        initialItemsCount: {
            type: Number,
            default: 1
        }
    },
    setup(props) {
        const isModalOpen = ref(false);
        const blockRef = ref<HTMLElement | null>(null);
        const contentRef = ref<HTMLElement | null>(null);
        const itemRefs = ref<HTMLElement[]>([]);

        const visibleItems = computed(() => props.items.slice(0, props.initialItemsCount));

        const openModal = () => {
            isModalOpen.value = true;
            document.body.style.overflow = 'hidden';
        };

        const closeModal = () => {
            isModalOpen.value = false;
            document.body.style.overflow = '';
        };

        return {
            isModalOpen,
            openModal,
            closeModal,
            blockRef,
            contentRef,
            itemRefs,
            visibleItems
        };
    },
})
</script>

<style>
@font-face {
    font-family: 'PlatformaFont';
    src: url('/root/Platforma_maxim/Platforma/static/frontend/vueapp/src/assets/fonts/TildaSans-VF_TTF_Web/TildaSans-VF.ttf') format('truetype');
}

.platforma-font {
    font-family: 'PlatformaFont', sans-serif;
}

.education-block {
    background-color: white;
    border-radius: 12px;
    padding: 24px;
    display: flex;
    flex-direction: column;
    height: 160px;
    overflow: hidden;
    font-family: 'PlatformaFont', sans-serif;
}

.education-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.education-header h2 {
    font-size: 24px;
    font-weight: 600;
    color: #333;
    margin: 0;
}

.items-count {
    background: #4a5568;
    color: white;
    padding: 2px 8px;
    border-radius: 4px;
    font-size: 14px;
}

.education-content {
    flex-grow: 1;
    overflow-y: auto;
}

.education-item {
    margin-bottom: 20px;
}

.program {
    font-size: 18px;
    font-weight: 600;
    color: #333;
    margin-bottom: 4px;
}

.institution {
    font-size: 16px;
    color: #666;
}

.show-more-btn {
    background: none;
    border: none;
    color: #5850ec;
    font-size: 14px;
    cursor: pointer;
    padding: 0;
    margin-top: 10px;
    transition: color 0.3s ease;
}

.show-more-btn:hover {
    color: #4338ca;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background-color: white;
    padding: 32px;
    border-radius: 12px;
    max-width: 600px;
    width: 90%;
    max-height: 80vh;
    overflow-y: auto;
    position: relative;
    font-family: 'PlatformaFont', sans-serif;
}

.modal-content h2 {
    font-size: 24px;
    font-weight: 600;
    color: #333;
    margin-bottom: 20px;
}

.close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    color: #333;
}

.modal-enter-active,
.modal-leave-active {
    transition: all 0.3s ease;
}

.modal-enter-from,
.modal-leave-to {
    opacity: 0;
}

.modal-enter-from .modal-content,
.modal-leave-to .modal-content {
    transform: scale(0.9);
}
</style>

