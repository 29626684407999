<template>
    <div id="events">
        <!-- <BackButtonVue /> -->
        <Spacer-32 />
        <CategoryFilter :categories="categories" :selectedCategory="selectedCategory"
            @category-selected="filterByCategory" :selectCategory="(category) => {
                this.selectedCategory = category
                this.$emit('category-selected', category);
                console.log(this.selectedCategory)
            }">
            <DropdownMenuVue label="Формат" :options="formats" @select="handleFormatSelect" />
        </CategoryFilter>
<!-- 
        <Spacer-32 />
        <label>Event price</label>
        <input type="number" v-model="event_price" placeholder="Точно" />
        <input type="number" v-model="event_priceGt" placeholder="Больше чем" />
        <input type="number" v-model="event_priceLt" placeholder="Меньше чем" />

        <Spacer-32 />
        <label>Event Date (After)</label>
        <input type="date" v-model="event_dateAfter" />
        <label>Event Date (Before)</label>
        <input type="date" v-model="event_dateBefore" />

        <Spacer-32 />
        <button @click="applyFilters">Apply Filters</button>
-->

        <Spacer-32 />
        <!-- <div class="card-list">
            <div v-for="event of events" :key="event.id">

                <link-to-event :id="event.id">

                    <router-link :to="{name: 'EventInfo', params: {id: event.id} }" style="text-decoration: none; color: inherit;">
                    <EventCardVue :event_title="event.event_title" :event_date="event.event_date"
                        :event_price="event.event_price" :event_imageUrl="event.event_imageUrl"
                        :event_ageRestriction="event.event_ageRestriction"
                        :event_availiableSpots="event.event_availiableSpots" :event_totalSpots="event.event_totalSpots"
                        :event_category="event.event_category" :id="event.id">
                    </EventCardVue>
                </link-to-event>
                </router-link>
            </div>
        </div> -->
        <div class="masonry-container">
            <!-- Each column -->
            <div class="masonry-column" v-for="n in 3" :key="n">
                <div v-for="(event, index) in events.filter((_, i) => i % 3 === n - 1)" :key="index">

                    <link-to-event :id="45">
                    <!-- <link-to-event :id="event.id"> -->

                        <!-- <router-link :to="{name: 'EventInfo', params: {id: event.id} }" style="text-decoration: none; color: inherit;"> -->
                        <EventCardVue :event_title="event.event_title" :event_date="event.event_date"
                            :event_price="event.event_price" :event_imageUrl="event.event_imageUrl"
                            :event_ageRestriction="event.event_ageRestriction"
                            :event_availiableSpots="event.event_availiableSpots"
                            :event_totalSpots="event.event_totalSpots" :event_category="event.event_category"
                            :id="event.id">
                        </EventCardVue>
                    </link-to-event>
                    <!-- </router-link> -->
                </div>
            </div>
        </div>
        <div>
            <!-- <h1> The end of page </h1> -->
        </div>
    </div>
</template>

<script>

import EventCardVue from "../components/EventCard.vue";
import CategoryFilter from '../components/CategoryFilter.vue';
import Spacer32 from '../components/Spacers/SpacerModule32.vue';
// import BackButtonVue from '../components/Navigations/BackButton.vue';
import DropdownMenuVue from '../components/DropdownMenu.vue';
import LinkToEvent from "../components/Links/LinkToEvent.vue";
import axios from 'axios'


export default {
    name: 'AllEvents',
    components: {
        CategoryFilter,
        EventCardVue,
        Spacer32,
        // BackButtonVue,
        DropdownMenuVue,
        LinkToEvent,
    },
    data() {
        return {
            formats: ['Формат 1', 'Формат 2', 'Формат 3'],
            categories: [
                'Все категории',
                'Здоровье',
                'Карьера',
                'Образование',
                'Красота',
                'Хобби',
                'Просвещение'
            ],
            selectedCategory: 'Все категории',
            events: [],
            currentPage: 1,
            hasNext: true,
            event_price: null,
            event_priceGt: null,
            event_priceLt: null,
            event_dateAfter: null,
            event_dateBefore: null,
        };
    },
    methods: {
        handleFormatSelect(option) {
            console.log('Selected format:', option);
        },

        async applyFilters() {
            try {
                const params = {
                    event_price: this.event_price,
                    event_price_gt: this.event_priceGt,
                    event_price_lt: this.event_priceLt,
                    event_date_after: this.event_dateAfter,
                    event_date_before: this.event_dateBefore,
                };
                const response = await axios.get('https://platforma34.tw1.ru/api/events/GetEvents/', { params });
                console.log(response)
                this.events = response.data;
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        },
        // async fetchEvents() {
        //   try {
        //     const url = 'http://platforma34.tw1.ru/api/events/GetEvents/'
        //     const response = await axios.get(url)
        //     const results = response.data.results
        //     this.posts = results.map(post => ({
        //       title: post.title,
        //       abstract: post.abstract,
        //       url: post.url,
        //       thumbnail: this.extractImage(post).url,
        //       caption: this.extractImage(post).caption,
        //       byline: post.byline,
        //       published_date: post.published_date,
        //     }))
        //   } catch (err) {
        //     if (err.response) {
        //       // client received an error response (5xx, 4xx)
        //       console.log("Server Error:", err)
        //     } else if (err.request) {
        //       // client never received a response, or request never left
        //       console.log("Network Error:", err)
        //     } else {
        //       console.log("Client Error:", err)
        //     }
        //   }
        // },

        // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

        // async fetchData() {
        //   try {
        //     const response = await axios.get('https://platforma34.tw1.ru/api/events/GetEvents/');
        //     this.events = response.data;
        //     console.log(response.data)
        //     console.log(this.events)
        //   } catch (error) {
        //     console.error('Error fetching data:', error);
        //   }
        // },

        // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

        getEvents() {
            console.log('Get Events')



            axios.get(`https://platforma34.tw1.ru/api/events/paginated_events/?page=${this.currentPage}`)
                .then(response => {
                    const data = response.data;
                    console.log(data);

                    this.hasNext = !!data.next; // Set hasNext based on whether there is a next page

                    // Push the new posts into the existing posts array
                    this.events.push(...data.results);
                })
                .catch(error => {
                    console.log(error);
                });
            // fetch(`/get_posts/?page=${this.currentPage}`)
            //     .then(response => {
            //         return response.json()
            //     })
            //     .then(data => {
            //         console.log(data)

            //         this.hasNext = false

            //         if (data.next) {
            //             this.hasNext = true
            //         }

            //         for (let i = 0; i < data.results.length; i++) {
            //             this.posts.push(data.results[i])
            //         }
            //     })
            //     .catch(error => {
            //         console.log(error)
            //     })
        }


        // send_request() {
        //     axios.get('http://platforma34.tw1.ru/api/user/GetUsers/')
        //         .then(function (response) {
        //             // this.events = response.data
        //             console.log(response);
        //         })
        //         .catch(function (error) {
        //             console.log(error);
        //         })
        //         .finally(function () {
        //             // always executed
        //         })
        // }, 
    },
    mounted() {
        // this.getEvents()
        this.applyFilters();
        window.onscroll = () => {
            let bottomOfWindow = document.documentElement.scrollTop + window.innerHeight === document.documentElement.offsetHeight

            if (bottomOfWindow && this.hasNext) {
                this.currentPage += 1
                this.getEvents()
            }
        };
    }
}
</script>

<style scoped>
/* html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;

} */

.card-list {
    display: grid;
    margin: 0;
    padding: 0;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 32px 8px;
    width: 100%;
}

#events {
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.masonry-container {
    display: flex;
    /* Flexbox to create columns */
    gap: 3vw;
    /* Space between columns */
    margin-left: 3vw;
}

/* Each column */
.masonry-column {
    flex: 1;
    /* Each column takes up an equal share of the container */
    display: flex;
    flex-direction: column;
    /* Stack items vertically */
    gap: 3vw;
    /* Space between items within each column */
    max-width: calc((100vw - 16vw - 2*4vw)/3);
}

</style>
