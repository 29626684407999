<template>
    <div class="education">
        <h2 class="section-title">Опыт работы</h2>

        <div class="experience-form" v-if="showForm">
            <div class="form-group">
                <label>Должность</label>
                <input type="text" v-model="position" placeholder="Владелец" class="form-input">
            </div>

            <div class="form-group">
                <label>Место работы</label>
                <input type="text" v-model="workplace" placeholder="Гранд Кафе 12" class="form-input">
            </div>

            <div class="date-inputs">
                <div class="form-group">
                    <label>Начало работы</label>
                    <div class="date-select">
                        <select v-model="startMonth" class="form-select">
                            <option v-for="month in months" :key="month" :value="month">{{ month }}</option>
                        </select>
                        <select v-model="startYear" class="form-select">
                            <option v-for="year in years" :key="year" :value="year">{{ year }}</option>
                        </select>
                    </div>
                </div>

                <div class="form-group">
                    <label>Конец работы</label>
                    <div class="date-select">
                        <select v-model="endMonth" class="form-select">
                            <option v-for="month in months" :key="month" :value="month">{{ month }}</option>
                        </select>
                        <select v-model="endYear" class="form-select">
                            <option v-for="year in years" :key="year" :value="year">{{ year }}</option>
                        </select>
                    </div>
                </div>
            </div>

            <button class="save-button" @click="saveExperience">Сохранить</button>
        </div>

        <div class="experience-list">
            <div class="experience-item" v-for="exp in experiences" :key="exp.id">
                <div class="experience-content">
                    <div class="experience-period">{{ exp.period }}</div>
                    <div class="experience-position">{{ exp.position }}</div>
                    <div class="experience-workplace">{{ exp.workplace }}</div>
                </div>
                <div class="experience-actions">
                    <button class="edit-button" @click="editExperience(exp)">
                        <EditIcon class="icon" />
                    </button>
                    <button class="delete-button" @click="deleteExperience(exp.id)">
                        <XIcon class="icon" />
                    </button>
                </div>
            </div>
        </div>

        <button class="add-button" @click="showForm = true">
            <PlusIcon class="icon" />
            Добавить место работы
        </button>

        <h2 class="section-title">Образование</h2>

        <div class="education-list">
            <div class="education-item" v-for="edu in educations" :key="edu.id">
                <div class="education-content">
                    <div class="education-period">{{ edu.period }}</div>
                    <div class="education-degree">{{ edu.degree }}</div>
                    <div class="education-institution">{{ edu.institution }}</div>
                </div>
                <div class="education-actions">
                    <button class="edit-button" @click="editEducation(edu)">
                        <EditIcon class="icon" />
                    </button>
                    <button class="delete-button" @click="deleteEducation(edu.id)">
                        <XIcon class="icon" />
                    </button>
                </div>
            </div>
        </div>

        <button class="add-button" @click="showEducationForm = true">
            <PlusIcon class="icon" />
            Добавить образование
        </button>
    </div>
</template>

<script setup>
/* eslint-disable */
import { ref } from 'vue'
import { EditIcon, XIcon, PlusIcon } from 'lucide-vue-next'

const showForm = ref(false)
const showEducationForm = ref(false)
const position = ref('')
const workplace = ref('')
const startMonth = ref('Январь')
const startYear = ref('2020')
const endMonth = ref('Январь')
const endYear = ref('2020')

const months = [
    'Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь',
    'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'
]

const years = Array.from({ length: 10 }, (_, i) => 2024 - i)

const experiences = ref([
    {
        id: 1,
        period: 'Янв 2020 — Настоящее время',
        position: 'Владелец',
        workplace: 'Гранд кафе 12'
    }
])

const educations = ref([
    {
        id: 1,
        period: '2011 - 2014',
        degree: 'Технология аналитического контроля химических соединений',
        institution: 'РАНХиГС'
    },
    {
        id: 2,
        period: '2011 - 2014',
        degree: 'Технология аналитического контроля химических соединений',
        institution: 'РАНХиГС'
    }
])

const saveExperience = () => {
    // Logic to save experience
}

const editExperience = (exp) => {
    // Logic to edit experience
}

const deleteExperience = (id) => {
    // Logic to delete experience
}

const editEducation = (edu) => {
    // Logic to edit education
}

const deleteEducation = (id) => {
    // Logic to delete education
}
</script>

<style scoped>
.education {
    max-width: 640px;
}

.section-title {
    font-size: 18px;
    font-weight: 600;
    color: #101828;
    margin-bottom: 24px;
}

.experience-form,
.education-form {
    background: #fff;
    border: 1px solid #EAECF0;
    border-radius: 8px;
    padding: 24px;
    margin-bottom: 24px;
}

.form-group {
    margin-bottom: 16px;
}

.form-group label {
    display: block;
    font-size: 14px;
    font-weight: 500;
    color: #344054;
    margin-bottom: 6px;
}

.form-input {
    width: 100%;
    padding: 10px 14px;
    border: 1px solid #D0D5DD;
    border-radius: 8px;
    font-size: 16px;
}

.date-inputs {
    display: flex;
    gap: 24px;
}

.date-select {
    display: flex;
    gap: 12px;
}

.form-select {
    padding: 10px 14px;
    border: 1px solid #D0D5DD;
    border-radius: 8px;
    font-size: 16px;
    min-width: 120px;
}

.save-button {
    background: #7F56D9;
    color: white;
    border: none;
    padding: 10px 18px;
    border-radius: 8px;
    font-weight: 600;
    cursor: pointer;
}

.experience-item,
.education-item {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 16px 0;
    border-bottom: 1px solid #EAECF0;
}

.experience-period,
.education-period {
    font-size: 14px;
    color: #475467;
    margin-bottom: 4px;
}

.experience-position,
.education-degree {
    font-weight: 500;
    color: #101828;
    margin-bottom: 4px;
}

.experience-workplace,
.education-institution {
    color: #475467;
}

.experience-actions,
.education-actions {
    display: flex;
    gap: 8px;
}

.edit-button,
.delete-button {
    background: none;
    border: none;
    padding: 4px;
    cursor: pointer;
    color: #98A2B3;
}

.icon {
    width: 20px;
    height: 20px;
}

.add-button {
    display: flex;
    align-items: center;
    gap: 8px;
    background: #F9FAFB;
    border: none;
    padding: 12px 20px;
    border-radius: 8px;
    color: #344054;
    font-weight: 500;
    cursor: pointer;
    margin-top: 16px;
}
</style>