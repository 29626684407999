<template>
    <div class="masonry-container">
        <!-- Each column -->
        <div class="masonry-column" v-for="n in 3" :key="n">
            <!-- Filter events into each column -->
            <div v-for="(event, index) in events.filter((_, i) => i % 3 === n - 1)" :key="index">
                <link-to-event :id="event.id">

                    <!-- <router-link :to="{name: 'EventInfo', params: {id: event.id} }" style="text-decoration: none; color: inherit;"> -->
                    <EventCardVue :event_title="event.event_title" :event_date="event.event_date"
                        :event_price="event.event_price" :event_imageUrl="event.event_imageUrl"
                        :event_ageRestriction="event.event_ageRestriction"
                        :event_availiableSpots="event.event_availiableSpots" :event_totalSpots="event.event_totalSpots"
                        :event_category="event.event_category" :id="event.id">
                    </EventCardVue>
                </link-to-event>
            </div>
            <!-- <div class="masonry-item" v-for="(event, index) in events.filter((_, i) => i % 3 === n - 1)" :key="index">
                <img :src="event.event_imageUrl" :alt="event.event_title" />
                <div class="multiline-truncate">
                    Название мероприятия длинное которое должно обрезаться рано или поздно
                </div>
                <span class="event-date">{{ formated(event.event_date) }}</span>
                <div class="truncate">
                    Имя Фамилия
                </div>
            </div> -->
        </div>
    </div>
</template>

<script lang="ts">
/* eslint-disable */

import { defineComponent } from 'vue';
import moment from 'moment';
import LinkToEvent from '@/components/Links/LinkToEvent.vue';
import EventCardVue from '@/components/EventCard.vue';

export interface Event {
    event_title: string;
    event_description: string;
    event_mentor: number | null;
    event_id_mentor: string;
    event_date: string;
    event_price: number;
    event_ageRestriction: string;
    event_availiableSpots: number;
    event_totalSpots: number;
    event_imageUrl: string;
    event_category: string;
    event_members_id: number[];
    event_conference_id: string;
    event_conference_password: string;
    event_white_list: boolean;
    event_public: boolean;
    event_canceled: boolean;
    event_private: boolean;
}
// images: 
// https://avatars.mds.yandex.net/i?id=fc0e813c7aa52b349c80764409c97e3e_l-5288046-images-thumbs&n=13
// https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/f/25b4baea-66f0-44f9-abf9-45b7f7032d28/d2pcrkk-f1593820-0eb1-4400-a7e9-c4ea989c6a82.jpg/v1/fill/w_900,h_507,q_75,strp/stones_wallpaper_1920x1080_by_eman333_d2pcrkk-fullview.jpg?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOjdlMGQxODg5ODIyNjQzNzNhNWYwZDQxNWVhMGQyNmUwIiwiaXNzIjoidXJuOmFwcDo3ZTBkMTg4OTgyMjY0MzczYTVmMGQ0MTVlYTBkMjZlMCIsIm9iaiI6W1t7ImhlaWdodCI6Ijw9NTA3IiwicGF0aCI6IlwvZlwvMjViNGJhZWEtNjZmMC00NGY5LWFiZjktNDViN2Y3MDMyZDI4XC9kMnBjcmtrLWYxNTkzODIwLTBlYjEtNDQwMC1hN2U5LWM0ZWE5ODljNmE4Mi5qcGciLCJ3aWR0aCI6Ijw9OTAwIn1dXSwiYXVkIjpbInVybjpzZXJ2aWNlOmltYWdlLm9wZXJhdGlvbnMiXX0.fSqsMCEydhh4k-nmSipK7VHeBSxAMnvchgAFXMBQ9Mw
// https://a.d-cd.net/cGAAAgC8BuA-1920.jpg
// https://cdn.zmescience.com/wp-content/uploads/2021/05/wp2295404.jpg
// https://a.d-cd.net/49ffb3u-960.jpg
// https://i.pinimg.com/originals/cc/20/5f/cc205fa861d99844981e00ccfe360d37.jpg
// https://avatars.mds.yandex.net/i?id=fb37d24839206114c37decb9149ecc43_l-5275813-images-thumbs&n=13

export default defineComponent({
    name: 'NewEventsPage',
    /* eslint-disable */
    components: {
        EventCardVue,
        LinkToEvent,
    },
    setup() {
        /* eslint-disable */
        const images = [
            "https://avatars.mds.yandex.net/i?id=fc0e813c7aa52b349c80764409c97e3e_l-5288046-images-thumbs&n=13",
            "https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/f/25b4baea-66f0-44f9-abf9-45b7f7032d28/d2pcrkk-f1593820-0eb1-4400-a7e9-c4ea989c6a82.jpg/v1/fill/w_900,h_507,q_75,strp/stones_wallpaper_1920x1080_by_eman333_d2pcrkk-fullview.jpg?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOjdlMGQxODg5ODIyNjQzNzNhNWYwZDQxNWVhMGQyNmUwIiwiaXNzIjoidXJuOmFwcDo3ZTBkMTg4OTgyMjY0MzczYTVmMGQ0MTVlYTBkMjZlMCIsIm9iaiI6W1t7ImhlaWdodCI6Ijw9NTA3IiwicGF0aCI6IlwvZlwvMjViNGJhZWEtNjZmMC00NGY5LWFiZjktNDViN2Y3MDMyZDI4XC9kMnBjcmtrLWYxNTkzODIwLTBlYjEtNDQwMC1hN2U5LWM0ZWE5ODljNmE4Mi5qcGciLCJ3aWR0aCI6Ijw9OTAwIn1dXSwiYXVkIjpbInVybjpzZXJ2aWNlOmltYWdlLm9wZXJhdGlvbnMiXX0.fSqsMCEydhh4k-nmSipK7VHeBSxAMnvchgAFXMBQ9Mw",
            "https://a.d-cd.net/cGAAAgC8BuA-1920.jpg",
            "https://cdn.zmescience.com/wp-content/uploads/2021/05/wp2295404.jpg",
            "https://a.d-cd.net/49ffb3u-960.jpg",
            "https://i.pinimg.com/originals/cc/20/5f/cc205fa861d99844981e00ccfe360d37.jpg",
            "https://avatars.mds.yandex.net/i?id=fb37d24839206114c37decb9149ecc43_l-5275813-images-thumbs&n=13",
            "https://avatars.mds.yandex.net/i?id=7ee32a1d3debbd3d89dace9e1144556f_l-12496215-images-thumbs&n=13",
            "https://avatars.mds.yandex.net/i?id=a61bd1323d4932885c2a1b47a118e022ea53bad7-7942200-images-thumbs&n=13",
        ]
        const events = [
            {
                id: 1,
                event_title: "Event 1",
                event_description: "Description for Event 1",
                event_mentor: 45,
                event_id_mentor: "45",
                event_date: "2024-12-12T10:00",
                event_price: 100,
                event_ageRestriction: "18+",
                event_availiableSpots: 10,
                event_totalSpots: 20,
                event_imageUrl: images[0],
                event_category: "Health",
                event_members_id: [1, 2, 3],
                event_conference_id: "CONF001",
                event_conference_password: "pass123",
                event_white_list: false,
                event_public: true,
                event_canceled: false,
                event_private: false,
            },
            {
                id: 2,
                event_title: "Название мероприятия очень длинное которое точно не будет влезать в две строки и которое точно придется переносить куда-то",
                event_description: "Description for Event 2",
                event_mentor: 2,
                event_id_mentor: "ID002",
                event_date: "2024-12-13T11:00",
                event_price: 200,
                event_ageRestriction: "21+",
                event_availiableSpots: 15,
                event_totalSpots: 25,
                event_imageUrl: images[1],
                event_category: "Category B",
                event_members_id: [4, 5, 6],
                event_conference_id: "CONF002",
                event_conference_password: "pass456",
                event_white_list: true,
                event_public: false,
                event_canceled: false,
                event_private: true,
            },
            {
                id: 3,
                event_title: "Название мероприятия очень длинное которое точно не будет влезать в две строки и которое точно придется переносить куда-то",
                event_description: "Description for Event 2",
                event_mentor: 2,
                event_id_mentor: "ID002",
                event_date: "2024-12-13T11:00",
                event_price: 200,
                event_ageRestriction: "21+",
                event_availiableSpots: 15,
                event_totalSpots: 25,
                event_imageUrl: images[2],
                event_category: "Category B",
                event_members_id: [4, 5, 6],
                event_conference_id: "CONF002",
                event_conference_password: "pass456",
                event_white_list: true,
                event_public: false,
                event_canceled: false,
                event_private: true,
            },
            {
                id: 4,
                event_title: "Название мероприятия очень длинное которое точно не будет влезать в две строки и которое точно придется переносить куда-то",
                event_description: "Description for Event 2",
                event_mentor: 2,
                event_id_mentor: "ID002",
                event_date: "2024-12-13T11:00",
                event_price: 200,
                event_ageRestriction: "21+",
                event_availiableSpots: 15,
                event_totalSpots: 25,
                event_imageUrl: images[3],
                event_category: "Category B",
                event_members_id: [4, 5, 6],
                event_conference_id: "CONF002",
                event_conference_password: "pass456",
                event_white_list: true,
                event_public: false,
                event_canceled: false,
                event_private: true,
            },
            {
                id: 5,
                event_title: "Название мероприятия очень длинное которое точно не будет влезать в две строки и которое точно придется переносить куда-то",
                event_description: "Description for Event 2",
                event_mentor: 2,
                event_id_mentor: "ID002",
                event_date: "2024-12-13T11:00",
                event_price: 200,
                event_ageRestriction: "21+",
                event_availiableSpots: 15,
                event_totalSpots: 25,
                event_imageUrl: images[4],
                event_category: "Category B",
                event_members_id: [4, 5, 6],
                event_conference_id: "CONF002",
                event_conference_password: "pass456",
                event_white_list: true,
                event_public: false,
                event_canceled: false,
                event_private: true,
            },
            {
                id: 6,
                event_title: "Название мероприятия очень длинное которое точно не будет влезать в две строки и которое точно придется переносить куда-то",
                event_description: "Description for Event 2",
                event_mentor: 2,
                event_id_mentor: "ID002",
                event_date: "2024-12-13T11:00",
                event_price: 200,
                event_ageRestriction: "21+",
                event_availiableSpots: 15,
                event_totalSpots: 25,
                event_imageUrl: images[5],
                event_category: "Category B",
                event_members_id: [4, 5, 6],
                event_conference_id: "CONF002",
                event_conference_password: "pass456",
                event_white_list: true,
                event_public: false,
                event_canceled: false,
                event_private: true,
            },
            {
                id: 7,
                event_title: "Название мероприятия очень длинное которое точно не будет влезать в две строки и которое точно придется переносить куда-то",
                event_description: "Description for Event 2",
                event_mentor: 2,
                event_id_mentor: "ID002",
                event_date: "2024-12-13T11:00",
                event_price: 200,
                event_ageRestriction: "21+",
                event_availiableSpots: 15,
                event_totalSpots: 25,
                event_imageUrl: images[6],
                event_category: "Category B",
                event_members_id: [4, 5, 6],
                event_conference_id: "CONF002",
                event_conference_password: "pass456",
                event_white_list: true,
                event_public: false,
                event_canceled: false,
                event_private: true,
            },
            {
                id: 7,
                event_title: "Название мероприятия очень длинное которое точно не будет влезать в две строки и которое точно придется переносить куда-то",
                event_description: "Description for Event 2",
                event_mentor: 2,
                event_id_mentor: "ID002",
                event_date: "2024-12-13T11:00",
                event_price: 200,
                event_ageRestriction: "21+",
                event_availiableSpots: 15,
                event_totalSpots: 25,
                event_imageUrl: images[7],
                event_category: "Category B",
                event_members_id: [4, 5, 6],
                event_conference_id: "CONF002",
                event_conference_password: "pass456",
                event_white_list: true,
                event_public: false,
                event_canceled: false,
                event_private: true,
            },
            {
                id: 67,
                event_title: "Название мероприятия очень длинное которое точно не будет влезать в две строки и которое точно придется переносить куда-то",
                event_description: "Description for Event 2",
                event_mentor: 2,
                event_id_mentor: "ID002",
                event_date: "2024-12-13T11:00",
                event_price: 200,
                event_ageRestriction: "21+",
                event_availiableSpots: 15,
                event_totalSpots: 25,
                event_imageUrl: images[8],
                event_category: "Category B",
                event_members_id: [4, 5, 6],
                event_conference_id: "CONF002",
                event_conference_password: "pass456",
                event_white_list: true,
                event_public: false,
                event_canceled: false,
                event_private: true,
            },
            {
                id: 8,
                event_title: "Название мероприятия очень длинное которое точно не будет влезать в две строки и которое точно придется переносить куда-то",
                event_description: "Description for Event 2",
                event_mentor: 2,
                event_id_mentor: "ID002",
                event_date: "2024-12-13T11:00",
                event_price: 200,
                event_ageRestriction: "21+",
                event_availiableSpots: 15,
                event_totalSpots: 25,
                event_imageUrl: images[0],
                event_category: "Category B",
                event_members_id: [4, 5, 6],
                event_conference_id: "CONF002",
                event_conference_password: "pass456",
                event_white_list: true,
                event_public: false,
                event_canceled: false,
                event_private: true,
            },
            {
                id: 9,
                event_title: "Название мероприятия очень длинное которое точно не будет влезать в две строки и которое точно придется переносить куда-то",
                event_description: "Description for Event 2",
                event_mentor: 2,
                event_id_mentor: "ID002",
                event_date: "2024-12-13T11:00",
                event_price: 200,
                event_ageRestriction: "21+",
                event_availiableSpots: 15,
                event_totalSpots: 25,
                event_imageUrl: images[1],
                event_category: "Category B",
                event_members_id: [4, 5, 6],
                event_conference_id: "CONF002",
                event_conference_password: "pass456",
                event_white_list: true,
                event_public: false,
                event_canceled: false,
                event_private: true,
            },
            {
                id: 10,
                event_title: "Название мероприятия очень длинное которое точно не будет влезать в две строки и которое точно придется переносить куда-то",
                event_description: "Description for Event 2",
                event_mentor: 2,
                event_id_mentor: "ID002",
                event_date: "2024-12-13T11:00",
                event_price: 200,
                event_ageRestriction: "21+",
                event_availiableSpots: 15,
                event_totalSpots: 25,
                event_imageUrl: images[2],
                event_category: "Category B",
                event_members_id: [4, 5, 6],
                event_conference_id: "CONF002",
                event_conference_password: "pass456",
                event_white_list: true,
                event_public: false,
                event_canceled: false,
                event_private: true,
            },
            {
                id: 11,
                event_title: "Название мероприятия очень длинное которое точно не будет влезать в две строки и которое точно придется переносить куда-то",
                event_description: "Description for Event 2",
                event_mentor: 2,
                event_id_mentor: "ID002",
                event_date: "2024-12-13T11:00",
                event_price: 200,
                event_ageRestriction: "21+",
                event_availiableSpots: 15,
                event_totalSpots: 25,
                event_imageUrl: images[3],
                event_category: "Category B",
                event_members_id: [4, 5, 6],
                event_conference_id: "CONF002",
                event_conference_password: "pass456",
                event_white_list: true,
                event_public: false,
                event_canceled: false,
                event_private: true,
            },
            {
                id: 12,
                event_title: "Название мероприятия очень длинное которое точно не будет влезать в две строки и которое точно придется переносить куда-то",
                event_description: "Description for Event 2",
                event_mentor: 2,
                event_id_mentor: "ID002",
                event_date: "2024-12-13T11:00",
                event_price: 200,
                event_ageRestriction: "21+",
                event_availiableSpots: 15,
                event_totalSpots: 25,
                event_imageUrl: images[4],
                event_category: "Category B",
                event_members_id: [4, 5, 6],
                event_conference_id: "CONF002",
                event_conference_password: "pass456",
                event_white_list: true,
                event_public: false,
                event_canceled: false,
                event_private: true,
            },
            {
                id: 13,
                event_title: "Название мероприятия очень длинное которое точно не будет влезать в две строки и которое точно придется переносить куда-то",
                event_description: "Description for Event 2",
                event_mentor: 2,
                event_id_mentor: "ID002",
                event_date: "2024-12-13T11:00",
                event_price: 200,
                event_ageRestriction: "21+",
                event_availiableSpots: 15,
                event_totalSpots: 25,
                event_imageUrl: images[5],
                event_category: "Category B",
                event_members_id: [4, 5, 6],
                event_conference_id: "CONF002",
                event_conference_password: "pass456",
                event_white_list: true,
                event_public: false,
                event_canceled: false,
                event_private: true,
            },

            {
                id: 7,
                event_title: "Название мероприятия очень длинное которое точно не будет влезать в две строки и которое точно придется переносить куда-то",
                event_description: "Description for Event 2",
                event_mentor: 2,
                event_id_mentor: "ID002",
                event_date: "2024-12-13T11:00",
                event_price: 200,
                event_ageRestriction: "21+",
                event_availiableSpots: 15,
                event_totalSpots: 25,
                event_imageUrl: images[7],
                event_category: "Category B",
                event_members_id: [4, 5, 6],
                event_conference_id: "CONF002",
                event_conference_password: "pass456",
                event_white_list: true,
                event_public: false,
                event_canceled: false,
                event_private: true,
            },
            {
                id: 15,
                event_title: "Название мероприятия очень длинное которое точно не будет влезать в две строки и которое точно придется переносить куда-то",
                event_description: "Description for Event 2",
                event_mentor: 2,
                event_id_mentor: "ID002",
                event_date: "2024-12-13T11:00",
                event_price: 200,
                event_ageRestriction: "21+",
                event_availiableSpots: 15,
                event_totalSpots: 25,
                event_imageUrl: images[0],
                event_category: "Category B",
                event_members_id: [4, 5, 6],
                event_conference_id: "CONF002",
                event_conference_password: "pass456",
                event_white_list: true,
                event_public: false,
                event_canceled: false,
                event_private: true,
            },
            {
                id: 16,
                event_title: "Название мероприятия очень длинное которое точно не будет влезать в две строки и которое точно придется переносить куда-то",
                event_description: "Description for Event 2",
                event_mentor: 2,
                event_id_mentor: "ID002",
                event_date: "2024-12-13T11:00",
                event_price: 200,
                event_ageRestriction: "21+",
                event_availiableSpots: 15,
                event_totalSpots: 25,
                event_imageUrl: images[1],
                event_category: "Category B",
                event_members_id: [4, 5, 6],
                event_conference_id: "CONF002",
                event_conference_password: "pass456",
                event_white_list: true,
                event_public: false,
                event_canceled: false,
                event_private: true,
            },
            {
                id: 17,
                event_title: "Название мероприятия очень длинное которое точно не будет влезать в две строки и которое точно придется переносить куда-то",
                event_description: "Description for Event 2",
                event_mentor: 2,
                event_id_mentor: "ID002",
                event_date: "2024-12-13T11:00",
                event_price: 200,
                event_ageRestriction: "21+",
                event_availiableSpots: 15,
                event_totalSpots: 25,
                event_imageUrl: images[1],
                event_category: "Category B",
                event_members_id: [4, 5, 6],
                event_conference_id: "CONF002",
                event_conference_password: "pass456",
                event_white_list: true,
                event_public: false,
                event_canceled: false,
                event_private: true,
            },
            {
                id: 18,
                event_title: "Название мероприятия очень длинное которое точно не будет влезать в две строки и которое точно придется переносить куда-то",
                event_description: "Description for Event 2",
                event_mentor: 2,
                event_id_mentor: "ID002",
                event_date: "2024-12-13T11:00",
                event_price: 200,
                event_ageRestriction: "21+",
                event_availiableSpots: 15,
                event_totalSpots: 25,
                event_imageUrl: images[2],
                event_category: "Category B",
                event_members_id: [4, 5, 6],
                event_conference_id: "CONF002",
                event_conference_password: "pass456",
                event_white_list: true,
                event_public: false,
                event_canceled: false,
                event_private: true,
            },
            {
                id: 19,
                event_title: "Название мероприятия очень длинное которое точно не будет влезать в две строки и которое точно придется переносить куда-то",
                event_description: "Description for Event 2",
                event_mentor: 2,
                event_id_mentor: "ID002",
                event_date: "2024-12-13T11:00",
                event_price: 200,
                event_ageRestriction: "21+",
                event_availiableSpots: 15,
                event_totalSpots: 25,
                event_imageUrl: images[3],
                event_category: "Category B",
                event_members_id: [4, 5, 6],
                event_conference_id: "CONF002",
                event_conference_password: "pass456",
                event_white_list: true,
                event_public: false,
                event_canceled: false,
                event_private: true,
            },
            {
                id: 69,
                event_title: "Название мероприятия очень длинное которое точно не будет влезать в две строки и которое точно придется переносить куда-то",
                event_description: "Description for Event 2",
                event_mentor: 2,
                event_id_mentor: "ID002",
                event_date: "2024-12-13T11:00",
                event_price: 200,
                event_ageRestriction: "21+",
                event_availiableSpots: 15,
                event_totalSpots: 25,
                event_imageUrl: images[8],
                event_category: "Category B",
                event_members_id: [4, 5, 6],
                event_conference_id: "CONF002",
                event_conference_password: "pass456",
                event_white_list: true,
                event_public: false,
                event_canceled: false,
                event_private: true,
            },
            {
                id: 20,
                event_title: "Название мероприятия очень длинное которое точно не будет влезать в две строки и которое точно придется переносить куда-то",
                event_description: "Description for Event 2",
                event_mentor: 2,
                event_id_mentor: "ID002",
                event_date: "2024-12-13T11:00",
                event_price: 200,
                event_ageRestriction: "21+",
                event_availiableSpots: 15,
                event_totalSpots: 25,
                event_imageUrl: images[4],
                event_category: "Category B",
                event_members_id: [4, 5, 6],
                event_conference_id: "CONF002",
                event_conference_password: "pass456",
                event_white_list: true,
                event_public: false,
                event_canceled: false,
                event_private: true,
            },
            {
                id: 21,
                event_title: "Название мероприятия очень длинное которое точно не будет влезать в две строки и которое точно придется переносить куда-то",
                event_description: "Description for Event 2",
                event_mentor: 2,
                event_id_mentor: "ID002",
                event_date: "2024-12-13T11:00",
                event_price: 200,
                event_ageRestriction: "21+",
                event_availiableSpots: 15,
                event_totalSpots: 25,
                event_imageUrl: images[5],
                event_category: "Category B",
                event_members_id: [4, 5, 6],
                event_conference_id: "CONF002",
                event_conference_password: "pass456",
                event_white_list: true,
                event_public: false,
                event_canceled: false,
                event_private: true,
            },
            {
                id: 22,
                event_title: "Название мероприятия очень длинное которое точно не будет влезать в две строки и которое точно придется переносить куда-то",
                event_description: "Description for Event 2",
                event_mentor: 2,
                event_id_mentor: "ID002",
                event_date: "2024-12-13T11:00",
                event_price: 200,
                event_ageRestriction: "21+",
                event_availiableSpots: 15,
                event_totalSpots: 25,
                event_imageUrl: images[6],
                event_category: "Category B",
                event_members_id: [4, 5, 6],
                event_conference_id: "CONF002",
                event_conference_password: "pass456",
                event_white_list: true,
                event_public: false,
                event_canceled: false,
                event_private: true,
            },
            {
                id: 7,
                event_title: "Название мероприятия очень длинное которое точно не будет влезать в две строки и которое точно придется переносить куда-то",
                event_description: "Description for Event 2",
                event_mentor: 2,
                event_id_mentor: "ID002",
                event_date: "2024-12-13T11:00",
                event_price: 200,
                event_ageRestriction: "21+",
                event_availiableSpots: 15,
                event_totalSpots: 25,
                event_imageUrl: images[7],
                event_category: "Category B",
                event_members_id: [4, 5, 6],
                event_conference_id: "CONF002",
                event_conference_password: "pass456",
                event_white_list: true,
                event_public: false,
                event_canceled: false,
                event_private: true,
            },
            {
                id: 23,
                event_title: "Название мероприятия очень длинное которое точно не будет влезать в две строки и которое точно придется переносить куда-то",
                event_description: "Description for Event 2",
                event_mentor: 2,
                event_id_mentor: "ID002",
                event_date: "2024-12-13T11:00",
                event_price: 200,
                event_ageRestriction: "21+",
                event_availiableSpots: 15,
                event_totalSpots: 25,
                event_imageUrl: images[0],
                event_category: "Category B",
                event_members_id: [4, 5, 6],
                event_conference_id: "CONF002",
                event_conference_password: "pass456",
                event_white_list: true,
                event_public: false,
                event_canceled: false,
                event_private: true,
            },
            {
                id: 24,
                event_title: "Название мероприятия очень длинное которое точно не будет влезать в две строки и которое точно придется переносить куда-то",
                event_description: "Description for Event 2",
                event_mentor: 2,
                event_id_mentor: "ID002",
                event_date: "2024-12-13T11:00",
                event_price: 200,
                event_ageRestriction: "21+",
                event_availiableSpots: 15,
                event_totalSpots: 25,
                event_imageUrl: images[1],
                event_category: "Category B",
                event_members_id: [4, 5, 6],
                event_conference_id: "CONF002",
                event_conference_password: "pass456",
                event_white_list: true,
                event_public: false,
                event_canceled: false,
                event_private: true,
            },
            {
                id: 25,
                event_title: "Название мероприятия очень длинное которое точно не будет влезать в две строки и которое точно придется переносить куда-то",
                event_description: "Description for Event 2",
                event_mentor: 2,
                event_id_mentor: "ID002",
                event_date: "2024-12-13T11:00",
                event_price: 200,
                event_ageRestriction: "21+",
                event_availiableSpots: 15,
                event_totalSpots: 25,
                event_imageUrl: images[2],
                event_category: "Category B",
                event_members_id: [4, 5, 6],
                event_conference_id: "CONF002",
                event_conference_password: "pass456",
                event_white_list: true,
                event_public: false,
                event_canceled: false,
                event_private: true,
            },
            {
                id: 70,
                event_title: "Название мероприятия очень длинное которое точно не будет влезать в две строки и которое точно придется переносить куда-то",
                event_description: "Description for Event 2",
                event_mentor: 2,
                event_id_mentor: "ID002",
                event_date: "2024-12-13T11:00",
                event_price: 200,
                event_ageRestriction: "21+",
                event_availiableSpots: 15,
                event_totalSpots: 25,
                event_imageUrl: images[8],
                event_category: "Category B",
                event_members_id: [4, 5, 6],
                event_conference_id: "CONF002",
                event_conference_password: "pass456",
                event_white_list: true,
                event_public: false,
                event_canceled: false,
                event_private: true,
            },
            {
                id: 26,
                event_title: "Название мероприятия очень длинное которое точно не будет влезать в две строки и которое точно придется переносить куда-то",
                event_description: "Description for Event 2",
                event_mentor: 2,
                event_id_mentor: "ID002",
                event_date: "2024-12-13T11:00",
                event_price: 200,
                event_ageRestriction: "21+",
                event_availiableSpots: 15,
                event_totalSpots: 25,
                event_imageUrl: images[3],
                event_category: "Category B",
                event_members_id: [4, 5, 6],
                event_conference_id: "CONF002",
                event_conference_password: "pass456",
                event_white_list: true,
                event_public: false,
                event_canceled: false,
                event_private: true,
            },
            {
                id: 27,
                event_title: "Название мероприятия очень длинное которое точно не будет влезать в две строки и которое точно придется переносить куда-то",
                event_description: "Description for Event 2",
                event_mentor: 2,
                event_id_mentor: "ID002",
                event_date: "2024-12-13T11:00",
                event_price: 200,
                event_ageRestriction: "21+",
                event_availiableSpots: 15,
                event_totalSpots: 25,
                event_imageUrl: images[4],
                event_category: "Category B",
                event_members_id: [4, 5, 6],
                event_conference_id: "CONF002",
                event_conference_password: "pass456",
                event_white_list: true,
                event_public: false,
                event_canceled: false,
                event_private: true,
            },
            {
                id: 28,
                event_title: "Название мероприятия очень длинное которое точно не будет влезать в две строки и которое точно придется переносить куда-то",
                event_description: "Description for Event 2",
                event_mentor: 2,
                event_id_mentor: "ID002",
                event_date: "2024-12-13T11:00",
                event_price: 200,
                event_ageRestriction: "21+",
                event_availiableSpots: 15,
                event_totalSpots: 25,
                event_imageUrl: images[5],
                event_category: "Category B",
                event_members_id: [4, 5, 6],
                event_conference_id: "CONF002",
                event_conference_password: "pass456",
                event_white_list: true,
                event_public: false,
                event_canceled: false,
                event_private: true,
            },
            {
                id: 29,
                event_title: "Название мероприятия очень длинное которое точно не будет влезать в две строки и которое точно придется переносить куда-то",
                event_description: "Description for Event 2",
                event_mentor: 2,
                event_id_mentor: "ID002",
                event_date: "2024-12-13T11:00",
                event_price: 200,
                event_ageRestriction: "21+",
                event_availiableSpots: 15,
                event_totalSpots: 25,
                event_imageUrl: images[6],
                event_category: "Category B",
                event_members_id: [4, 5, 6],
                event_conference_id: "CONF002",
                event_conference_password: "pass456",
                event_white_list: true,
                event_public: false,
                event_canceled: false,
                event_private: true,
            },
            {
                id: 30,
                event_title: "Название мероприятия очень длинное которое точно не будет влезать в две строки и которое точно придется переносить куда-то",
                event_description: "Description for Event 2",
                event_mentor: 2,
                event_id_mentor: "ID002",
                event_date: "2024-12-13T11:00",
                event_price: 200,
                event_ageRestriction: "21+",
                event_availiableSpots: 15,
                event_totalSpots: 25,
                event_imageUrl: images[0],
                event_category: "Category B",
                event_members_id: [4, 5, 6],
                event_conference_id: "CONF002",
                event_conference_password: "pass456",
                event_white_list: true,
                event_public: false,
                event_canceled: false,
                event_private: true,
            },
            {
                id: 31,
                event_title: "Название мероприятия очень длинное которое точно не будет влезать в две строки и которое точно придется переносить куда-то",
                event_description: "Description for Event 2",
                event_mentor: 2,
                event_id_mentor: "ID002",
                event_date: "2024-12-13T11:00",
                event_price: 200,
                event_ageRestriction: "21+",
                event_availiableSpots: 15,
                event_totalSpots: 25,
                event_imageUrl: images[1],
                event_category: "Category B",
                event_members_id: [4, 5, 6],
                event_conference_id: "CONF002",
                event_conference_password: "pass456",
                event_white_list: true,
                event_public: false,
                event_canceled: false,
                event_private: true,
            },
            {
                id: 32,
                event_title: "Название мероприятия очень длинное которое точно не будет влезать в две строки и которое точно придется переносить куда-то",
                event_description: "Description for Event 2",
                event_mentor: 2,
                event_id_mentor: "ID002",
                event_date: "2024-12-13T11:00",
                event_price: 200,
                event_ageRestriction: "21+",
                event_availiableSpots: 15,
                event_totalSpots: 25,
                event_imageUrl: images[2],
                event_category: "Category B",
                event_members_id: [4, 5, 6],
                event_conference_id: "CONF002",
                event_conference_password: "pass456",
                event_white_list: true,
                event_public: false,
                event_canceled: false,
                event_private: true,
            },
            {
                id: 68,
                event_title: "Название мероприятия очень длинное которое точно не будет влезать в две строки и которое точно придется переносить куда-то",
                event_description: "Description for Event 2",
                event_mentor: 2,
                event_id_mentor: "ID002",
                event_date: "2024-12-13T11:00",
                event_price: 200,
                event_ageRestriction: "21+",
                event_availiableSpots: 15,
                event_totalSpots: 25,
                event_imageUrl: images[8],
                event_category: "Category B",
                event_members_id: [4, 5, 6],
                event_conference_id: "CONF002",
                event_conference_password: "pass456",
                event_white_list: true,
                event_public: false,
                event_canceled: false,
                event_private: true,
            },
            {
                id: 33,
                event_title: "Название мероприятия очень длинное которое точно не будет влезать в две строки и которое точно придется переносить куда-то",
                event_description: "Description for Event 2",
                event_mentor: 2,
                event_id_mentor: "ID002",
                event_date: "2024-12-13T11:00",
                event_price: 200,
                event_ageRestriction: "21+",
                event_availiableSpots: 15,
                event_totalSpots: 25,
                event_imageUrl: images[3],
                event_category: "Category B",
                event_members_id: [4, 5, 6],
                event_conference_id: "CONF002",
                event_conference_password: "pass456",
                event_white_list: true,
                event_public: false,
                event_canceled: false,
                event_private: true,
            },
            {
                id: 34,
                event_title: "Название мероприятия очень длинное которое точно не будет влезать в две строки и которое точно придется переносить куда-то",
                event_description: "Description for Event 2",
                event_mentor: 2,
                event_id_mentor: "ID002",
                event_date: "2024-12-13T11:00",
                event_price: 200,
                event_ageRestriction: "21+",
                event_availiableSpots: 15,
                event_totalSpots: 25,
                event_imageUrl: images[4],
                event_category: "Category B",
                event_members_id: [4, 5, 6],
                event_conference_id: "CONF002",
                event_conference_password: "pass456",
                event_white_list: true,
                event_public: false,
                event_canceled: false,
                event_private: true,
            },
            {
                id: 35,
                event_title: "Название мероприятия очень длинное которое точно не будет влезать в две строки и которое точно придется переносить куда-то",
                event_description: "Description for Event 2",
                event_mentor: 2,
                event_id_mentor: "ID002",
                event_date: "2024-12-13T11:00",
                event_price: 200,
                event_ageRestriction: "21+",
                event_availiableSpots: 15,
                event_totalSpots: 25,
                event_imageUrl: images[5],
                event_category: "Category B",
                event_members_id: [4, 5, 6],
                event_conference_id: "CONF002",
                event_conference_password: "pass456",
                event_white_list: true,
                event_public: false,
                event_canceled: false,
                event_private: true,
            },
            {
                id: 36,
                event_title: "Название мероприятия очень длинное которое точно не будет влезать в две строки и которое точно придется переносить куда-то",
                event_description: "Description for Event 2",
                event_mentor: 2,
                event_id_mentor: "ID002",
                event_date: "2024-12-13T11:00",
                event_price: 200,
                event_ageRestriction: "21+",
                event_availiableSpots: 15,
                event_totalSpots: 25,
                event_imageUrl: images[6],
                event_category: "Category B",
                event_members_id: [4, 5, 6],
                event_conference_id: "CONF002",
                event_conference_password: "pass456",
                event_white_list: true,
                event_public: false,
                event_canceled: false,
                event_private: true,
            },
            {
                id: 7,
                event_title: "Название мероприятия очень длинное которое точно не будет влезать в две строки и которое точно придется переносить куда-то",
                event_description: "Description for Event 2",
                event_mentor: 2,
                event_id_mentor: "ID002",
                event_date: "2024-12-13T11:00",
                event_price: 200,
                event_ageRestriction: "21+",
                event_availiableSpots: 15,
                event_totalSpots: 25,
                event_imageUrl: images[7],
                event_category: "Category B",
                event_members_id: [4, 5, 6],
                event_conference_id: "CONF002",
                event_conference_password: "pass456",
                event_white_list: true,
                event_public: false,
                event_canceled: false,
                event_private: true,
            },
            {
                id: 37,
                event_title: "Название мероприятия очень длинное которое точно не будет влезать в две строки и которое точно придется переносить куда-то",
                event_description: "Description for Event 2",
                event_mentor: 2,
                event_id_mentor: "ID002",
                event_date: "2024-12-13T11:00",
                event_price: 200,
                event_ageRestriction: "21+",
                event_availiableSpots: 15,
                event_totalSpots: 25,
                event_imageUrl: images[0],
                event_category: "Category B",
                event_members_id: [4, 5, 6],
                event_conference_id: "CONF002",
                event_conference_password: "pass456",
                event_white_list: true,
                event_public: false,
                event_canceled: false,
                event_private: true,
            },
            {
                id: 38,
                event_title: "Название мероприятия очень длинное которое точно не будет влезать в две строки и которое точно придется переносить куда-то",
                event_description: "Description for Event 2",
                event_mentor: 2,
                event_id_mentor: "ID002",
                event_date: "2024-12-13T11:00",
                event_price: 200,
                event_ageRestriction: "21+",
                event_availiableSpots: 15,
                event_totalSpots: 25,
                event_imageUrl: images[1],
                event_category: "Category B",
                event_members_id: [4, 5, 6],
                event_conference_id: "CONF002",
                event_conference_password: "pass456",
                event_white_list: true,
                event_public: false,
                event_canceled: false,
                event_private: true,
            },
            {
                id: 39,
                event_title: "Название мероприятия очень длинное которое точно не будет влезать в две строки и которое точно придется переносить куда-то",
                event_description: "Description for Event 2",
                event_mentor: 2,
                event_id_mentor: "ID002",
                event_date: "2024-12-13T11:00",
                event_price: 200,
                event_ageRestriction: "21+",
                event_availiableSpots: 15,
                event_totalSpots: 25,
                event_imageUrl: images[2],
                event_category: "Category B",
                event_members_id: [4, 5, 6],
                event_conference_id: "CONF002",
                event_conference_password: "pass456",
                event_white_list: true,
                event_public: false,
                event_canceled: false,
                event_private: true,
            },
            {
                id: 40,
                event_title: "Название мероприятия очень длинное которое точно не будет влезать в две строки и которое точно придется переносить куда-то",
                event_description: "Description for Event 2",
                event_mentor: 2,
                event_id_mentor: "ID002",
                event_date: "2024-12-13T11:00",
                event_price: 200,
                event_ageRestriction: "21+",
                event_availiableSpots: 15,
                event_totalSpots: 25,
                event_imageUrl: images[3],
                event_category: "Category B",
                event_members_id: [4, 5, 6],
                event_conference_id: "CONF002",
                event_conference_password: "pass456",
                event_white_list: true,
                event_public: false,
                event_canceled: false,
                event_private: true,
            },
            {
                id: 41,
                event_title: "Название мероприятия очень длинное которое точно не будет влезать в две строки и которое точно придется переносить куда-то",
                event_description: "Description for Event 2",
                event_mentor: 2,
                event_id_mentor: "ID002",
                event_date: "2024-12-13T11:00",
                event_price: 200,
                event_ageRestriction: "21+",
                event_availiableSpots: 15,
                event_totalSpots: 25,
                event_imageUrl: images[4],
                event_category: "Category B",
                event_members_id: [4, 5, 6],
                event_conference_id: "CONF002",
                event_conference_password: "pass456",
                event_white_list: true,
                event_public: false,
                event_canceled: false,
                event_private: true,
            },
            {
                id: 42,
                event_title: "Название мероприятия очень длинное которое точно не будет влезать в две строки и которое точно придется переносить куда-то",
                event_description: "Description for Event 2",
                event_mentor: 2,
                event_id_mentor: "ID002",
                event_date: "2024-12-13T11:00",
                event_price: 200,
                event_ageRestriction: "21+",
                event_availiableSpots: 15,
                event_totalSpots: 25,
                event_imageUrl: images[5],
                event_category: "Category B",
                event_members_id: [4, 5, 6],
                event_conference_id: "CONF002",
                event_conference_password: "pass456",
                event_white_list: true,
                event_public: false,
                event_canceled: false,
                event_private: true,
            },
            {
                id: 43,
                event_title: "Название мероприятия очень длинное которое точно не будет влезать в две строки и которое точно придется переносить куда-то",
                event_description: "Description for Event 2",
                event_mentor: 2,
                event_id_mentor: "ID002",
                event_date: "2024-12-13T11:00",
                event_price: 200,
                event_ageRestriction: "21+",
                event_availiableSpots: 15,
                event_totalSpots: 25,
                event_imageUrl: images[6],
                event_category: "Category B",
                event_members_id: [4, 5, 6],
                event_conference_id: "CONF002",
                event_conference_password: "pass456",
                event_white_list: true,
                event_public: false,
                event_canceled: false,
                event_private: true,
            },
            {
                id: 7,
                event_title: "Название мероприятия очень длинное которое точно не будет влезать в две строки и которое точно придется переносить куда-то",
                event_description: "Description for Event 2",
                event_mentor: 2,
                event_id_mentor: "ID002",
                event_date: "2024-12-13T11:00",
                event_price: 200,
                event_ageRestriction: "21+",
                event_availiableSpots: 15,
                event_totalSpots: 25,
                event_imageUrl: images[7],
                event_category: "Category B",
                event_members_id: [4, 5, 6],
                event_conference_id: "CONF002",
                event_conference_password: "pass456",
                event_white_list: true,
                event_public: false,
                event_canceled: false,
                event_private: true,
            },
        ];
        /* eslint-disable */
        function formated(value: Date) {
            var some_date = moment(value).format('DD MMMM hh:mm');
            some_date = some_date.replace("September", "Сентября");
            some_date = some_date.replace("October", "Октября");
            some_date = some_date.replace("November", "Ноября");
            some_date = some_date.replace("December", "Декабря");
            some_date = some_date.replace("January", "Января");
            some_date = some_date.replace("February", "Февраля");
            some_date = some_date.replace("March", "Марта");
            some_date = some_date.replace("April", "Апреля");
            some_date = some_date.replace("May", "Мая");
            some_date = some_date.replace("June", "Июня");
            some_date = some_date.replace("July", "Июля");
            some_date = some_date.replace("August", "Августа");
            return some_date;
        }

        return {
            events,
            formated
        }
    },

});

</script>
<style scoped>
.masonry-container {
    display: flex;
    /* Flexbox to create columns */
    gap: 3vw;
    /* Space between columns */
    margin: 6vw;
}

/* Each column */
.masonry-column {
    flex: 1;
    /* Each column takes up an equal share of the container */
    display: flex;
    flex-direction: column;
    /* Stack items vertically */
    gap: 3vw;
    /* Space between items within each column */
    max-width: calc((100vw - 16vw - 2*4vw)/3);
}

/* Each item */
.masonry-item {
    overflow: hidden;
    /* Clip overflowing content */
    border-radius: 8px;
    /* Optional: Rounded corners */
    display: flex;
    flex-direction: column;
}

.masonry-item img {
    width: 100%;
    /* Full width of the container */
    height: auto;
    /* Maintain aspect ratio */
    display: block;
    /* Remove inline spacing issues */
    border-radius: 8px;
}

.break-words {
    padding: 8px;
    word-wrap: break-word;
    /* Legacy */
    word-break: break-word;
    /* Break long words */
    overflow-wrap: break-word;
    /* Modern standard */
}

.multiline-truncate {
    font-size: 24px;
    color: #232323;
    display: -webkit-box;
    /* Flex-like behavior */
    -webkit-line-clamp: 2;
    /* Number of lines to show */
    -webkit-box-orient: vertical;
    /* Vertical orientation */
    overflow: hidden;
    padding: 8px;
    /* Hide the rest */
}

.truncate {
    font-size: 22px;
    color: #6f6f6f;
    white-space: nowrap;
    /* Prevent wrapping */
    overflow: hidden;
    /* Hide overflowed text */
    text-overflow: ellipsis;
    padding: 8px;
    /* Add ellipsis */
}

.event-date {
    font-size: 18px;
    color: #555;
    padding: 16px;
}
</style>