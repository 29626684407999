<template>
    <router-link :to="{name: 'MentorInfo', params: {mentorId: this.mentorId} }" style="text-decoration: none; color: inherit;">
    <!-- <router-link :to="{name: 'MentorInfo2', params: {mentorId: 45} }" style="text-decoration: none; color: inherit;"> -->
        <slot>
        </slot>
    </router-link>
</template>

<script>
export default {
    name: 'LinkToMentor',
    props: {
        mentorId: {
            type: String,
            required: true,
        }
    },
}

</script>
<style scoped>

</style>