<template>
    <div class="experience-block" ref="blockRef">
      <div class="experience-header">
        <h2>{{ title }}</h2>
        <span class="items-count">{{ items.length }}</span>
      </div>
  
      <div class="experience-content" :style="contentStyle" ref="contentRef">
        <div 
          v-for="(item, index) in items" 
          :key="index" 
          class="experience-item" 
          :class="{ 'grayed-out': !isExpanded && index >= initialItemsCount }"
          ref="itemRefs"
        >
          <div class="date">{{ item.dateRange }}</div>
          <div class="position">{{ item.position }}</div>
          <div class="company">{{ item.company }}</div>
        </div>
      </div>
  
      <button v-if="items.length > initialItemsCount" @click="toggleExpand" class="show-more-btn">
        {{ isExpanded ? 'Скрыть' : 'Показать всё' }}
      </button>
    </div>
  </template>
  
  <script lang="ts">
  import { defineComponent, ref, computed, PropType, onMounted, watch, nextTick } from 'vue'
  
  interface ExperienceItem {
    dateRange: string;
    position: string;
    company: string;
  }
  
  export default defineComponent({
    name: 'ExperienceBlock',
    props: {
      title: {
        type: String,
        default: 'Опыт работы 10 лет'
      },
      items: {
        type: Array as PropType<ExperienceItem[]>,
        default: () => ([
          {
            dateRange: 'Янв 2020 — Настоящее время',
            position: 'Владелец',
            company: 'Грана кафе 12'
          },
          {
            dateRange: 'Янв 2018 — Дек 2019',
            position: 'Управляющий',
            company: 'Кафе Центральное'
          },
          {
            dateRange: 'Март 2015 — Дек 2017',
            position: 'Шеф-повар',
            company: 'Ресторан Премиум'
          }
        ])
      },
      initialItemsCount: {
        type: Number,
        default: 1
      }
    },
    setup(props) {
      const isExpanded = ref(false);
      const blockRef = ref<HTMLElement | null>(null);
      const contentRef = ref<HTMLElement | null>(null);
      const itemRefs = ref<HTMLElement[]>([]);
      const contentHeight = ref(0);
      const initialContentHeight = ref(0);
  
      const toggleExpand = () => {
        isExpanded.value = !isExpanded.value;
        updateContentHeight();
      };
  
      const contentStyle = computed(() => ({
        maxHeight: isExpanded.value ? `${contentHeight.value}px` : `${initialContentHeight.value}px`,
        overflow: 'hidden',
        transition: 'max-height 0.5s ease-in-out'
      }));
  
      const updateContentHeight = () => {
        nextTick(() => {
          if (contentRef.value && itemRefs.value.length > 0) {
            contentHeight.value = contentRef.value.scrollHeight;
            
            initialContentHeight.value = itemRefs.value
              .slice(0, props.initialItemsCount)
              .reduce((total, item) => total + item.offsetHeight, 0);
          }
        });
      };
  
      onMounted(() => {
        updateContentHeight();
        window.addEventListener('resize', updateContentHeight);
      });
  
      watch(() => props.items, updateContentHeight, { deep: true });
  
      return {
        isExpanded,
        toggleExpand,
        contentStyle,
        blockRef,
        contentRef,
        itemRefs
      };
    },
  })
  </script>
  
  <style scoped>
  .experience-block {
    background-color: white;
    border-radius: 12px;
    padding: 24px;
    display: flex;
    flex-direction: column;
    height: auto;
  }
  
  .experience-header {
    font-size: 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .experience-header h2 {
    font-size: 32px;
    font-weight: 600;
    color: #333;
    margin: 0;
  }
  
  .items-count {
    background: #4a5568;
    color: white;
    padding: 2px 8px;
    border-radius: 4px;
    font-size: 14px;
  }
  
  .experience-content {
    flex-grow: 1; /* Take remaining space */
    overflow-y: auto; /* Allow scrolling if content exceeds parent height */
  }
  
  .experience-item {
    margin-bottom: 20px;
    transition: opacity 0.3s ease;
  }
  
  .experience-item:last-child {
    margin-bottom: 0;
  }
  
  .experience-item.grayed-out {
    opacity: 0.5;
  }
  
  .date {
    font-size: 18px;
    color: #666;
    margin-bottom: 4px;
  }
  
  .position {
    font-size: 24px;
    font-weight: 600;
    color: #333;
    margin-bottom: 2px;
  }
  
  .company {
    font-size: 18px;
    color: #666;
  }
  
  .show-more-btn {
    background: none;
    border: none;
    color: #5850ec;
    font-size: 14px;
    cursor: pointer;
    padding: 0;
    margin-top: 10px;
    transition: color 0.3s ease;
  }
  
  .show-more-btn:hover {
    color: #4338ca;
  }
  </style>
  
  