<template>
    <div class="privacy-settings">
        <div class="field-group" v-for="field in fields" :key="field.id">
            <div class="field-content">
                <div class="field-label">{{ field.label }}</div>
                <div class="field-value">{{ field.value }}</div>
            </div>
            <button class="edit-button" @click="editField(field.id)">
                Редактировать
            </button>
        </div>
    </div>
</template>

<script setup>
/* eslint-disable */
import { ref } from 'vue'

const fields = ref([
    { id: 'age', label: 'Возраст', value: '16.02.1990' },
    { id: 'phone', label: 'Номер телефона', value: '+78005553535' },
    { id: 'email', label: 'Почта', value: 'jdakdkdak@mail.ru' },
    { id: 'password', label: 'Пароль', value: '********' },
    { id: 'vk', label: 'ВК', value: 'id804816821' },
    { id: 'telegram', label: 'Telegram', value: '@durov' },
    { id: 'website', label: 'Свой веб-сайт', value: 'https://sergey-meyerhold.com/' }
])

const editField = (fieldId) => {
    console.log('Editing field:', fieldId)
}
</script>

<style scoped>
.privacy-settings {
    max-width: 640px;
}

.field-group {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 24px 0;
    border-bottom: 1px solid #EAECF0;
}

.field-label {
    font-size: 14px;
    font-weight: 500;
    color: #344054;
    margin-bottom: 4px;
}

.field-value {
    color: #101828;
}

.edit-button {
    background: none;
    border: none;
    color: #7F56D9;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
}

.edit-button:hover {
    color: #6941C6;
}
</style>