import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "masonry-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EventCardVue = _resolveComponent("EventCardVue")!
  const _component_link_to_event = _resolveComponent("link-to-event")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(), _createElementBlock(_Fragment, null, _renderList(3, (n) => {
      return _createElementVNode("div", {
        class: "masonry-column",
        key: n
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.events.filter((_, i) => i % 3 === n - 1), (event, index) => {
          return (_openBlock(), _createElementBlock("div", { key: index }, [
            _createVNode(_component_link_to_event, {
              id: event.id
            }, {
              default: _withCtx(() => [
                _createVNode(_component_EventCardVue, {
                  event_title: event.event_title,
                  event_date: event.event_date,
                  event_price: event.event_price,
                  event_imageUrl: event.event_imageUrl,
                  event_ageRestriction: event.event_ageRestriction,
                  event_availiableSpots: event.event_availiableSpots,
                  event_totalSpots: event.event_totalSpots,
                  event_category: event.event_category,
                  id: event.id
                }, null, 8, ["event_title", "event_date", "event_price", "event_imageUrl", "event_ageRestriction", "event_availiableSpots", "event_totalSpots", "event_category", "id"])
              ]),
              _: 2
            }, 1032, ["id"])
          ]))
        }), 128))
      ])
    }), 64))
  ]))
}